import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import {
	getVoucher,
	putVoucher,
	deleteVoucher,
} from "../../../actions/voucher";
import {
	priceValidators,
	sessionsNumberValidators,
} from "../../../auxiliar/useFormValidators";

const EditVoucher = () => {
	const modalId = "delete-voucher-type-modal";
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		fetchFormValues,
		resetFetchedValues,
		openModal,
		watch,
		originalValues,
		errors,
		isSubmitting,
	} = useFormWithDisable(modalId);

	const navigate = useNavigate();
	const [isEditing, setIsEditing] = useState(false);
	const { voucher_type_id: voucherTypeId } = useParams();

	const restrictedBy = watch("restricted_by") || "sessions"
	const groupIndiv = watch("group_indiv") || "individual";
	const period = watch("validity_period_unit") || "m"

	useEffect(() => {
		if (voucherTypeId) fetchFormValues(getVoucher, voucherTypeId);
	}, [voucherTypeId]);

	useEffect(() => {
		if (!isEditing && voucherTypeId) resetFetchedValues();
	}, [isEditing]);

	const formConfig = {
		restricted_by: {
			condition: true,
			disabled: true,
			type: "selector",
			name: "restricted_by",
			label: "Tipo de bono *",
			options: [
				{ key: "sessions", label: "Bono de sesiones" },
				{ key: "time", label: "Bono temporal" },
			],
			defaultValue: originalValues.restricted_by,
			validators: { required: "El tipo de bono es obligatorio" },
		},
		name: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "name",
			validators: { required: "El nombre es obligatorio" },
		},
		group_indiv: {
			condition: true,
			disabled: true,
			type: "selector",
			name: "group_indiv",
			label: "Sesiones o clases *",
			options: [
				{ key: "individual", label: "Sesiones individuales" },
				{ key: "group", label: "Clases" },
			],
			defaultValue: "individual",
			validators: { required: "El tipo de bono es obligatorio" },
		},
		session_type_id: {
			condition: groupIndiv === "individual",
			disabled: true,
			type: "session_type_selector",
			name: "session_type_id",
			label: "Tipo de sesión *",
		},
		group_session_type_id: {
			condition: groupIndiv === "group",
			disabled: false,
			type: "group_session_type_selector",
			name: "group_session_type_id",
			label: "Tipo de clase *",
		},
		price: {
			condition: true,
			disabled: true,
			type: "number",
			label: "Precio del bono *",
			placeholder: "Ejemplo: 100",
			name: "price",
			info: "Precio del bono en euros",
			validators: priceValidators,
			minValue: 0,
		},
		total_sessions: {
			condition: true,
			disabled: !isEditing,
			type: "number",
			label: restrictedBy === "sessions" ? "Número de sesiones *" : "Número de sesiones máximo",
			placeholder: "Ejemplo: 10",
			name: "total_sessions",
			info: restrictedBy === "sessions" ? undefined : "El número máximo de sesiones es opcional en los bonos temporales",
			validators: restrictedBy === "sessions" ? sessionsNumberValidators : undefined,
			minValue: 1,
		},
		validity_period_unit: {
			condition: true,
			disabled: !isEditing,
			type: "selector",
			name: "validity_period_unit",
			label: "Periodo de validez *",
			options: [
				{ key: "m", label: "Meses" },
				{ key: "w", label: "Semanas" },
			],
			defaultValue: "m",
			validators: { required: "El periodo de validez es obligatorio" },
		},
		validity_periods: {
			condition: true,
			disabled: !isEditing,
			type: "number",
			label: period === "m" ? "Meses de validez *" : "Semanas de validez *",
			placeholder: "Ejemplo: 12",
			name: "validity_periods",
			info: period === "m" ?
				"Desde el día de la compra, cuando hayan pasado estos meses, el bono del paciente expirará." :
				"Desde el día de la compra, cuando hayan pasado estas semanas, el bono del paciente expirará.",
			minValue: 1,
			validators: {
				required: "Los meses de validez son obligatorios.",
				pattern: {
					value: /^\-{0,1}\d+?$/,
					message:
						"El número de meses de validez debe ser un número sin decimales",
				},
				validate: (v) =>
					Number(v) >= 0 || "Los meses de validez deben ser mayor que 0",
			},
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Editar Tipo de Bono</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					{!isEditing ? (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(setIsEditing, true)}
								disabled={isSubmitting}
							>
								Editar
							</button>
							<button
								className="btn btn-error btn-sm"
								onClick={openModal}
								disabled={isSubmitting}
							>
								Eliminar
							</button>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(navigate, -1)}
								disabled={isSubmitting}
							>
								Atrás
							</button>
						</>
					) : (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmit(
									putVoucher,
									voucherTypeId,
									handleSubmitWithoutValidation(setIsEditing, false)
								)}
								disabled={isSubmitting}
							>
								Guardar
							</button>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(setIsEditing, false)}
								disabled={isSubmitting}
							>
								Cancelar
							</button>
						</>
					)}
				</div>
			</form>

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar todos los datos del tipo de bono?"
				text="No podremos recuperar la información más adelante."
				onConfirm={handleSubmitWithoutValidation(deleteVoucher, voucherTypeId)}
			/>
		</div>
	);
};

export default EditVoucher;
