import axios from "axios";

export const postPatientAuthorizedAcquiredVoucher = (
	data,
	patientId,
	clinicId,
	acquiredVoucherId,
	refresh
) => {
	const reqData = {
		clinic_id: clinicId,
		acquired_voucher_id: acquiredVoucherId,
		patient_id: patientId,
		new_patient_info: patientId === 'new' ? {
			name: data.patient_name,
			surnames: data.patient_surnames,
			email: data.patient_email,
			phone_number: data.patient_phone_number,
		} : undefined
	}

	return async () => {
		return axios
			.post("/api/managers/patient_authorized_acquired_voucher/", reqData)
			.then(() => {
				refresh();
			})
			.catch(() => { });
	};
};

export const deletePatientAuthorizedAcquiredVoucher = (
	patientId,
	acquiredVoucherId,
	modalId,
	refresh
) => {
	return async () => {
		return axios
			.delete(
				`/api/managers/patient_authorized_acquired_voucher/acquired_voucher/${acquiredVoucherId}/patient/${patientId}`
			)
			.then(() => {
				document.getElementById(modalId).close();
				refresh();
			})
			.catch(() => { });
	};
};
