import ModalConfirm from "../general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../general/form/CustomForm";
import { deleteLogo, postLogo } from "../../actions/clinics";
import {
	checkLogo,
	isDefined,
} from "../../auxiliar/formatValidators";

const EditLogo = ({ originalLogoURL, clinicId, close }) => {
	const modalId = "delete-logo-modal";
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		openModal,
		errors,
		isSubmitting,
	} = useFormWithDisable(modalId);

	const formConfig = {
		logo: {
			condition: true,
			disabled: false,
			name: "logo",
			info: "Archivo jpg o png de menos de 25MB",
			label: "Logo",
			type: "file",
			tooltipLocation: "tooltip-right",
			validators: {
				required: "El logo es obligatorio",
				validate: checkLogo,
			},
		},
	};

	return (
		<div
			className={`mt-5 mb-12 grid grid-cols-1 gap-3 md:gap-5 ${isDefined(originalLogoURL) && "md:grid-cols-3"
				}`}
		>
			<div className="col-span-2">
				<h3 className="text-primary text-xl">Editar logo</h3>

				{originalLogoURL && <div className="md:hidden p-5 flex items-center justify-center">
					<img className="object-cover h-48 mx-auto" src={originalLogoURL} />
				</div>}

				<p className="lg:text-lg my-3">
					Aquí puedes subir el logo de tu clínica. Los pacientes podrán verlo al
					realizar una reserva online y en las facturas entre otros. Recuerda
					que el logo debe ser un archivo de tipo png o jpg.
				</p>

				<form className="mt-5 mb-5">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>

					<div className="mt-5 flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(postLogo, clinicId, close)}
							disabled={isSubmitting}
						>
							Guardar
						</button>

						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmitWithoutValidation(close)}
							disabled={isSubmitting}
						>
							Cancelar
						</button>

						{originalLogoURL && (
							<button
								className="btn btn-error btn-sm"
								onClick={openModal}
								disabled={isSubmitting}
							>
								Eliminar
							</button>
						)}
					</div>
				</form>
			</div>

			{originalLogoURL && <div className="hidden md:block p-5 flex items-center justify-center">
				<img className="object-cover h-48 mx-auto" src={originalLogoURL} />
			</div>}

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar el logo de la clínica?"
				text="No podremos recuperar la información más adelante."
				onConfirm={handleSubmitWithoutValidation(deleteLogo, clinicId, close)}
			/>
		</div>
	);
};

export default EditLogo;
