import { useEffect } from "react";

import CustomForm from "../general/form/CustomForm";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { getInvoice } from "../../actions/invoice";


const DisplayInvoice = ({ invoiceId }) => {
	const { register, fetchFormValues, errors } = useFormWithDisable();

	const formConfig = {
		concept: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Concepto",
			name: "concept",
		},
		recipient_name: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Pagador",
			name: "recipient_name",
		},
		issue_date: {
			condition: true,
			disabled: true,
			type: "date",
			label: "Fecha de emisión",
			name: "issue_date",
		},
		invoice_code: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Código de factura",
			name: "invoice_code",
		},
		total_paid: {
			condition: true,
			disabled: true,
			type: "number",
			label: "Pagado",
			name: "total_paid",
		},
	};

	useEffect(() => {
		if (invoiceId) fetchFormValues(getInvoice, invoiceId);
	}, [invoiceId]);

	return (
		<>
			<form className={"my-3 grid grid-cols-1 md:grid-cols-2 gap-3"}>
				<CustomForm
					formConfig={formConfig}
					errors={errors}
					register={register}
				/>
			</form>
		</>
	);
};

export default DisplayInvoice;
