import { useDispatch, useSelector } from "react-redux";
import { faPerson, faPeopleGroup, faTicket } from "@fortawesome/free-solid-svg-icons";

import OptionSelector from "../../../components/general/auxiliar/OptionSelector";
import OverviewVouchers from "../../../components/services/voucher/voucher/OverviewVouchers";
import OverviewSessionTypes from "../../../components/services/types/OverviewSessionTypes";
import OverviewClassTypes from "../../../components/services/types/OverviewClassTypes";
import { dispatchPersistedFiltersSave } from "../../../store/reducers/persistedFiltersReducer";


const OverviewServices = () => {
	const dispatch = useDispatch();
	const selected = useSelector((state) => state?.persistedFilters?.services?.displayed) || "sessions";

	const onServiceClick = (service) => {
		dispatchPersistedFiltersSave(dispatch, "services", { displayed: service });
	}

	const options = {
		sessions: {
			condition: true,
			title: "Sesiones",
			icon: faPerson,
			selected: selected === "sessions",
			onClick: () => onServiceClick("sessions"),
		},
		classes: {
			condition: true,
			title: "Clases",
			icon: faPeopleGroup,
			selected: selected === "classes",
			onClick: () => onServiceClick("classes"),
		},
		vouchers: {
			condition: true,
			title: "Bonos",
			icon: faTicket,
			selected: selected === "vouchers",
			onClick: () => onServiceClick("vouchers"),
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<div className="flex items-center justify-between">
				<h2 className="text-primary text-2xl">{options[selected]?.title}</h2>

				{/* Displayed service selector */}
				<OptionSelector options={options} />
			</div>

			{selected === "sessions" && <OverviewSessionTypes />}
			{selected === "classes" && <OverviewClassTypes />}
			{selected === "vouchers" && <OverviewVouchers />}
		</div>
	);
};

export default OverviewServices;