import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";
import CustomForm from "../../general/form/CustomForm";
import { uploadDocument } from "../../../actions/document";
import { checkFile } from "../../../auxiliar/formatValidators";
import { onClosePopup, onOpenPopup } from "../../../auxiliar/auxFunctions";

export const UploadDocumentPopup = ({ refresh }) => {
	const ref = useRef();
	const closeTooltip = () => ref.current.close();
	const { width: windowWidth } = useScreenSize();

	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		errors,
		isSubmitting,
	} = useFormWithDisable();

	const formConfig = {
		file: {
			condition: true,
			disabled: false,
			name: "file",
			info: "El archivo debe ocupar menos de 25MB",
			label: "Archivo *",
			type: "file",
			validators: {
				required: "El archivo es obligatorio",
				validate: checkFile,
			},
		},
	};

	const { patient_id: patientId } = useParams();
	const { id: activeClinicId } = useSelector((state) => state.activeClinic);

	return (
		<Popup
			onClose={onClosePopup(refresh)}
			onOpen={onOpenPopup()}
			ref={ref}
			modal
			position="bottom center"
			lockScroll
			trigger={
				<button className={"btn btn-primary btn-sm"}>
					<FontAwesomeIcon icon={faFolderPlus}  alt="Subir documento" />
				</button>
			}
			contentStyle={{ width: windowWidth > 768 ? "50%" : "90%" }}
		>
			<div className={"p-5 bg-base-100 rounded-lg lg:min-w-max"}>
				<h3 className="font-bold text-lg text-primary">Subir Documento</h3>

				<form className="mt-5 mb-5">
					<div className="grid grid-cols-1 gap-5">
						<CustomForm
							formConfig={formConfig}
							errors={errors}
							register={register}
						/>
					</div>

					<div className="mt-5 flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(
								uploadDocument,
								activeClinicId,
								patientId,
								closeTooltip
							)}
							disabled={isSubmitting}
						>
							Confirmar
						</button>

						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmitWithoutValidation(closeTooltip)}
							disabled={isSubmitting}
						>
							Cancelar
						</button>
					</div>
				</form>
			</div>
		</Popup>
	);
};

export default UploadDocumentPopup;
