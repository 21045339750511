import axios from "axios";
import { pick } from "lodash";

export const getAllCharges = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/charges/`, { params: params })
			.then((response) => {
				return { ...response.data, waiting: false };
			})
			.catch(() => { });
	};
};

export const postCharge = (data, clinicId, cashRegisterId, auxFunction) => {
	let paymentData = {};
	if (Number(data.paid) > 0) {
		paymentData = pick(data, ["initial_price", "discount", "paid", "payment_method"]);
	} else if (Number(data.returned) > 0) {
		paymentData = pick(data, ["initial_price", "discount", "payment_method"]);
		paymentData.paid = -Number(data.returned);
	} else {
		paymentData = pick(data, ["initial_price", "discount"]);
	}

	const reqData = {
		...paymentData,
		cash_register_id: cashRegisterId,
		clinic_id: clinicId.toString(),
	};

	return async () => {
		return axios
			.post("/api/managers/charges/", reqData)
			.then(() => {
				if (auxFunction) auxFunction();
			})
			.catch(() => { });
	};
};

export const deleteCharge = (chargeId, refresh) => {
	return async () => {
		return axios
			.delete(`/api/managers/charges/${chargeId}`)
			.then((response) => {
				refresh();
			})
			.catch(() => { });
	};
};