import FormSelector from "./FormSelector";

const CustomPaymentMethodSelector = ({ customKey, register, config, errors }) => {
	const options = [
		config.allowAll ?
			{ key: "all", label: "Todos" } :
			{ key: "select", label: "Selecciona un método de pago" },
		{ key: "Efectivo", label: "Efectivo" },
		{ key: "Tarjeta", label: "Tarjeta" },
		{ key: "Transferencia", label: "Transferencia" },
		{ key: "Otro", label: "Otro" },
	];

	return (
		<FormSelector
			customKey={customKey}
			register={register}
			config={{
				condition: config.condition,
				disabled: config.disabled,
				type: "selector",
				name: config.name,
				label: config.label,
				info: config.info,
				options: options,
				defaultValue: config.allowAll ? "all" : "select",
				validators: config.validators,
			}}
			errors={errors}
		/>
	);
};

export default CustomPaymentMethodSelector;
