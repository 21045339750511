import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { handleSubmit } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";


const OptionSelector = ({ options }) => {
    return (
        <div className="flex items-center">
            <div className="flex space-x-1 rounded-lg bg-base-200 p-0.5" role="tablist" aria-orientation="horizontal">
                {
                    options && Object.values(options).map((o) => {
                        if (!o.condition) return null

                        // n options in multiOption
                        const nOptions = isDefined(o.multiOption) ? Object.values(o.multiOption).filter(o => o.condition).length : 0

                        // if there's a multiOption with more than one option, show the dropdown
                        if (nOptions > 1) {
                            return (
                                <div
                                    key={o.title}
                                    className={`dropdown dropdown-end ${o.disabled && isDefined(o.disabledInfo) ? "tooltip tooltip-left" : ""}`}
                                    data-tip={o.disabledInfo}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                        className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold 
                                            lg:pr-3 hover:bg-base-300 ${o.selected ? "bg-base-300 shadow" : ""}`}
                                        disabled={o.disabled}
                                    >
                                        {o.icon && <FontAwesomeIcon icon={o.icon} className={!o.disabled ? "text-secondary" : "text-disabled-text"} />}

                                        {o.title && <span className={`sr-only lg:not-sr-only lg:ml-2 ${!o.disabled ? "text-primary" : "text-disabled-text"}`}>
                                            {o.title}
                                        </span>}
                                    </div>
                                    {!o.disabled && <ul
                                        tabIndex={0}
                                        className="dropdown-content z-40 menu p-2 shadow bg-base-200 rounded-box w-52"
                                    >
                                        {
                                            Object.values(o.multiOption).map((mo) => {
                                                if (!mo.condition) return null

                                                return <li key={mo.title.replace(/\s/g, '') + "_li"}>
                                                    <button
                                                        key={mo.title.replace(/\s/g, '')}
                                                        type="button"
                                                        className={`${mo.selected ? "bg-base-300 shadow" : "bg-base-200"} hover:bg-base-300 
                                                            disabled:cursor-not-allowed disabled:text-disabled-text disabled:bg-disabled-bg`}
                                                        disabled={mo.disabled}
                                                        onClick={handleSubmit(mo.onClick)}
                                                    >
                                                        <a>{mo.title}</a>
                                                    </button>
                                                </li>
                                            })
                                        }
                                    </ul>}
                                </div>
                            )
                        }

                        // if there's a single option or a multiOption with only one option, show the button
                        const mergedOption = {
                            title: o.title,
                            icon: o.icon,
                            selected: o.selected,
                            disabled: nOptions !== 1 ? o.disabled : Object.values(o.multiOption).filter(o => o.condition)[0].disabled,
                            onClick: nOptions !== 1 ? o.onClick : Object.values(o.multiOption).filter(o => o.condition)[0].onClick,
                            disabled: nOptions !== 1 ? o.disabled : Object.values(o.multiOption).filter(o => o.condition)[0].disabled,
                        }

                        return <button
                            key={mergedOption.title}
                            disabled={mergedOption.disabled}
                            className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold 
                                lg:pr-3 hover:bg-base-300 ${mergedOption.selected ? "bg-base-300 shadow" : ""}`}
                            id="headlessui-tabs-tab-:r1rg:"
                            role="tab"
                            type="button"
                            aria-selected={mergedOption.selected}
                            tabIndex={mergedOption.selected ? "0" : "-1"}
                            data-headlessui-state={mergedOption.selected ? "selected" : ""}
                            aria-controls="headlessui-tabs-panel-:r1ri:"
                            onClick={handleSubmit(mergedOption.onClick)}
                        >
                            {mergedOption.icon && <FontAwesomeIcon
                                icon={mergedOption.icon}
                                className={!mergedOption.disabled ? "text-secondary" : "text-disabled-text"}
                            />}
                            {mergedOption.title && <span
                                className={`sr-only lg:not-sr-only lg:ml-2 ${!mergedOption.disabled ? "text-primary" : "text-disabled-text"}`}
                            >
                                {mergedOption.title}
                            </span>}
                        </button>
                    }
                    )
                }
            </div>
        </div>
    );
};

export default OptionSelector;
