import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faArrowUpRightFromSquare, faEnvelope, faPen } from "@fortawesome/free-solid-svg-icons";

import WhatsAppReminder from "../auxiliar/WhatsAppReminder";

const FormInput = ({ customKey, register, config, errors }) => {
	return (
		<div key={customKey} className="form-control">
			{config.label && (
				<label htmlFor={config.name} className="label-text">
					{config.label}{" "}
					{config.info && (
						<div className="tooltip" data-tip={config.info}>
							<FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
						</div>
					)}

					{// This will only render in phone numbers
						config.whatsAppLink && (
							<WhatsAppReminder link={config.whatsAppLink} />
						)
					}

					{// This will only render in emails
						config.mailto && (
							<a href={config.mailto} className="mx-1">
								<FontAwesomeIcon icon={faEnvelope} className="text-primary" />
							</a>
						)
					}

					{// This will only render to redirect to another entity
						config.redirect && (
							<a href={config.redirect} target="_blank" className="mx-1">
								<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-primary" />
							</a>
						)
					}

					{// This will only render to open popups
						config.openPopup && (
							<FontAwesomeIcon
								icon={faPen}
								className="text-primary hover:cursor-pointer"
								onClick={config.openPopup}
							/>
						)
					}
				</label>
			)}

			<div className={`${errors && "tooltip"}`} data-tip={errors?.message}>
				<input
					className={`input input-bordered text-left w-full ${errors ? "input-error" : "input-primary"
						} disabled:bg-disabled-bg disabled:text-disabled-text placeholder-disabled-text`}
					type={config.type}
					id={config.name}
					placeholder={config.disabled ? "" : config.placeholder}
					disabled={config.disabled}
					defaultValue={config.defaultValue}
					autoComplete={config.autocomplete ? config.autocomplete : "off"}
					min={config.minValue}
					max={config.maxValue}
					{...register(config.name, config.validators ? config.validators : {
						required: false,
						validate: false,
						pattern: false,
						...["text", "password", "email"].includes(config.type) ? {
							minLength: 0,
							maxLength: Infinity,
						} : {},
						...config.type === "number" ? {
							max: Infinity,
							min: -Infinity,
						} : {}
					})}
				/>
			</div>
		</div>
	);
};

export default FormInput;
