import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dispatchMessageSave } from "../../store/reducers/messageReducer";
import { dispatchLogout } from "../../store/reducers/authReducer";
import { dispatchCookiesSave } from "../../store/reducers/cookiesReducer";
import { isDefined } from "../formatValidators";

const cookiesErrorMsgs = [
	"Token has expired",
	"Missing CSRF token",
	"CSRF double submit tokens do not match",
	'Missing cookie "access_token_cookie"',
	"Signature verification failed"
]

function useAxiosInterceptor() {
	const dispatch = useDispatch();
	const cookies = useSelector((state) => state.cookiesReducer.cookies);

	useEffect(() => {
		axios.defaults.withCredentials = true;

		const requestInterceptor = axios.interceptors.request.use(
			(config) => {
				if (config.data && typeof config.data === 'object') {
					for (const key in config.data) {
						if (key.includes('tmp')) {
							delete config.data[key];
						}
					}
				}
				return config;
			}, (error) => {
				return Promise.reject(error);
			});

		const responseInterceptor = axios.interceptors.response.use(
			(response) => {
				const originalRequest = response.config;

				if (response?.data?.cookies) {
					axios.defaults.headers.common["X-CSRF-TOKEN"] =
						response.data.cookies.csrf_access_token;

					dispatchCookiesSave(dispatch, {
						csrf_refresh_token: response.data.cookies.csrf_refresh_token,
						refresh_token: response.data.cookies.refresh_token,
					});
				}

				if (
					originalRequest.method !== "get" &&
					!["/api/managers/refresh_token", "/api/managers/login"].includes(
						originalRequest.url
					)
				) {
					dispatchMessageSave(dispatch, {
						msg: response.data.msg,
						type: "info",
					});
				}

				return response;
			},
			async (err) => {
				const originalRequest = err.config;
				const msg = err?.response?.data?.msg || JSON.parse(await err?.response?.data?.text()).msg

				// Console log in dev env
				if (process.env.NODE_ENV !== "production") {
					console.log(err);
				}

				// if it's a login try show error and don't retry
				if (originalRequest.url === "/api/managers/login") {
					dispatchMessageSave(dispatch, {
						msg: msg,
						type: "error",
					});

					return
				}

				// Display message in toast if its not a cookies issue or a problem not found handled elsewhere
				if (
					(![403, 404].includes(err.response.status)) &&
					!cookiesErrorMsgs.includes(msg)
				) {
					dispatchMessageSave(dispatch, {
						msg: msg,
						type: "error",
					});
				}

				// if the refresh failed close the session
				if (originalRequest.url === "/api/managers/refresh_token") {
					dispatchLogout(dispatch)
					dispatchMessageSave(dispatch, { msg: "Se ha cerrado la sesión por inactividad", type: "info" })
					return //Promise.reject(err);
				}
				// If it's a problem with cookies refresh token and retry
				else if (
					err?.response?.status === 401 &&
					cookiesErrorMsgs.includes(msg) &&
					!originalRequest._retry &&
					isDefined(cookies?.csrf_refresh_token)
				) {
					originalRequest._retry = true;

					// refresh request
					return axios.post(
						"/api/managers/refresh_token",
						{},
						{
							headers: {
								"X-CSRF-TOKEN": cookies?.csrf_refresh_token,
								"REFRESH-TOKEN": cookies?.refresh_token,
							},
						}
					)
						.then(async (response) => {
							// refresh cookies of old request and retry
							originalRequest.headers["X-CSRF-TOKEN"] = response.data.cookies.csrf_access_token;
							return axios(originalRequest);
						})
				}
				// If it's a problem with cookies and the params for the refresh are not available the session is closed
				else if (err?.response?.status === 422 || (
					err?.response?.status === 401 &&
					cookiesErrorMsgs.includes(msg)
				)) {
					dispatchLogout(dispatch)
					dispatchMessageSave(dispatch, { msg: "Se ha cerrado la sesión por inactividad", type: "info" })
					return
				}

				// Rejects the promise so error has to be controlled in the component
				return Promise.reject(err);
			}
		);

		// Clean up the interceptor when the component unmounts
		return () => {
			axios.interceptors.request.eject(requestInterceptor);
			axios.interceptors.response.eject(responseInterceptor);
		};
	}, [cookies]);
}

export default useAxiosInterceptor;
