import { useEffect } from "react";

import CustomForm from "../../../components/general/form/CustomForm";
import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import {
	deleteUnconfirmedSession,
	getUnconfirmedSession,
} from "../../../actions/unconfirmedSession";

const EditUnconfirmedBooking = () => {
	const modalId = "delete-unconfirmed-session-modal";
	const {
		register,
		handleSubmitWithoutValidation,
		fetchFormValues,
		openModal,
		errors,
		isSubmitting,
	} = useFormWithDisable(modalId);

	const queryParams = useQueryParams();
	const sessionCode = queryParams.get("session");

	useEffect(() => {
		fetchFormValues(getUnconfirmedSession, sessionCode);
	}, [sessionCode]);

	const formConfig = {
		patient_name: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Nombre *",
			name: "patient_name",
		},
		patient_surnames: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Apellidos *",
			name: "patient_surnames",
		},
		patient_email: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Email *",
			name: "patient_email",
		},
		patient_phone_number: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Teléfono",
			name: "patient_phone_number",
		},
		start_datetime: {
			condition: true,
			disabled: true,
			type: "datetime-local",
			label: "Fecha de inicio",
			name: "start_datetime",
		},
		finish_datetime: {
			condition: true,
			disabled: true,
			type: "datetime-local",
			label: "Fecha de fin",
			name: "finish_datetime",
		},
		employee_name: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Fisioterapeuta",
			name: "employee_name",
		},
		session_type_name: {
			condition: true,
			disabled: true,
			type: "text",
			label: "Tipo de sesión",
			name: "session_type_name",
		},
		session_reason: {
			condition: true,
			disabled: true,
			type: "textarea",
			label: "Motivo de consulta",
			name: "session_reason",
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Solicitud de Sesión</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-error btn-sm"
						onClick={openModal}
						disabled={isSubmitting}
					>
						Eliminar
					</button>
				</div>
			</form>

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar la solicitud de sesión?"
				text="No podremos recuperar la información más adelante y dejará de estar disponible para la clínica."
				onConfirm={
					handleSubmitWithoutValidation(
						deleteUnconfirmedSession,
						sessionCode,
						queryParams
					)}
			/>
		</div>
	);
};

export default EditUnconfirmedBooking;
