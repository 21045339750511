import { useEffect } from "react";
import { useSelector } from "react-redux";
import { format } from 'date-fns';

import CustomForm from "../../../general/form/CustomForm";
import { isDefined } from "../../../../auxiliar/formatValidators";
import { postClass } from "../../../../actions/class";


const RegisterClass = ({
    register,
    errors,
    isSubmitting,
    watch,
    setValue,
    handleSubmit,
    closeTooltip,
    eventData,
}) => {
    const activeClinic = useSelector((state) => state.activeClinic);

    const groupSessionTypeData = watch("group_session_type_data") || "select";
    const employeeId = watch("employee_id") || "select"
    const comment = watch("comments") || ""
    const startDatetime = watch("start_datetime") || ""
    const finishDatetime = watch("finish_datetime") || ""
    const firstDate = watch("first_date") || ""
    const startHour = watch("start_hour") || ""
    const finishHour = watch("finish_hour") || ""
    const recurrent = watch("recurrent") || false
    const repeatSome = watch("repeat_monday") || watch("repeat_tuesday") || watch("repeat_wednesday") || watch("repeat_thursday") || watch("repeat_friday") || watch("repeat_saturday") || watch("repeat_sunday") || false


    // Initially sets the start and finish date and hour based on the event data
    useEffect(() => {
        setValue("clinic_id", activeClinic.id)

        if (employeeId === "all")
            setValue("employee_id", "select")

        if (eventData.startAt)
            setValue("start_datetime", format(
                new Date(eventData.startAt),
                "yyyy-MM-dd'T'HH:mm"
            ).toString())
        if (eventData.endAt)
            setValue("finish_datetime", format(
                new Date(eventData.endAt),
                "yyyy-MM-dd'T'HH:mm"
            ).toString())
    }, [eventData]);

    // Sets the start and finish date when recurrent changes
    useEffect(() => {
        if (recurrent) {
            if (isDefined(startDatetime)) {
                setValue("first_date", (format(new Date(startDatetime), "yyyy-MM-dd")).toString())
                setValue("start_hour", (format(new Date(startDatetime), "HH:mm")).toString())
            }
            if (isDefined(finishDatetime)) {
                setValue("finish_hour", (format(new Date(finishDatetime), "HH:mm")).toString())
            }
        } else {
            if (isDefined(firstDate)) {
                const auxDateStart = new Date(firstDate)
                if (isDefined(startHour)) {
                    auxDateStart.setHours(parseInt(startHour.slice(0, 2)), parseInt(startHour.slice(3, 5)))
                }
                setValue("start_datetime", (format(auxDateStart, "yyyy-MM-dd'T'HH:mm")).toString())

                const auxDateFinish = new Date(firstDate)
                if (isDefined(finishHour)) {
                    auxDateFinish.setHours(parseInt(finishHour.slice(0, 2)), parseInt(finishHour.slice(3, 5)))
                }
                setValue("finish_datetime", (format(auxDateFinish, "yyyy-MM-dd'T'HH:mm")).toString())
            }
        }
    }, [recurrent]);

    // Sets the finish date and hour based on the session type duration
    useEffect(() => {
        const sessionTypeId = groupSessionTypeData.split("-")[0];
        if (isDefined(sessionTypeId)) {
            setValue("group_session_type_id", sessionTypeId)
        }

        const sessionDuration = groupSessionTypeData.split("-")[1] || 60;

        if (!recurrent && isDefined(startDatetime)) {
            let startingTime = new Date(startDatetime).getTime();
            if (isNaN(startingTime)) startingTime = new Date().getTime();

            setValue("finish_datetime", format(
                new Date(startingTime + sessionDuration * 60000),
                "yyyy-MM-dd'T'HH:mm"
            ).toString())
        } else if (recurrent && isDefined(startHour)) {
            let startingTime = new Date(
                new Date().setHours(
                    startHour.slice(0, 2),
                    startHour.slice(3, 5)
                )
            ).getTime();
            if (isNaN(startingTime)) startingTime = new Date().getTime();

            setValue("finish_hour", format(
                new Date(startingTime + sessionDuration * 60000),
                "HH:mm"
            ).toString())
        }
    }, [groupSessionTypeData, startDatetime, startHour]);

    const formConfig = {
        employee_id: {
            condition: true,
            disabled: false,
            type: "physio_selector",
            label: "Fisioterapeuta *",
            name: "employee_id",
            defaultValue: eventData.employee_id === "all" ? "select" : eventData.employee_id,
        },
        group_session_type_data: {
            condition: true,
            disabled: false,
            type: "group_session_type_selector_with_metadata",
            label: "Tipo de clase *",
            name: "group_session_type_data",
        },
        start_datetime: {
            condition: !recurrent,
            disabled: false,
            type: "datetime-local",
            label: "Fecha y hora de inicio *",
            name: "start_datetime",
            validators: {
                required: "Elegir una fecha y hora de inicio es obligatorio",
                validate: (v) => (!recurrent || isDefined(v)) || "Elegir una fecha y hora de inicio es obligatorio",
            },
        },
        finish_datetime: {
            condition: !recurrent,
            disabled: true,
            type: "datetime-local",
            label: "Fecha y hora de fin *",
            name: "finish_datetime",
            validators: {
                required: "Elegir una fecha y hora de fin es obligatorio",
                validate: (v) => {
                    if (recurrent) return true
                    if (!isDefined(v)) return "Elegir una fecha y hora de fin es obligatorio"
                    if (new Date(v).getTime() <= new Date(startDatetime).getTime()) return "La fecha de fin debe ser posterior a la de inicio"
                }
            },
        },
        first_date: {
            condition: recurrent,
            disabled: false,
            type: "date",
            label: "Fecha de inicio *",
            name: "first_date",
            validators: {
                required: "Elegir una fecha de inicio es obligatorio",
                validate: (v) => (recurrent || isDefined(v)) || "Elegir una fecha de inicio es obligatorio",
            },
        },
        last_date: {
            condition: recurrent,
            disabled: false,
            type: "date",
            label: "Fecha de fin *",
            name: "last_date",
            validators: {
                required: "Elegir una fecha de fin es obligatorio",
                validate: (v) => {
                    if (!recurrent) return true
                    if (!isDefined(v)) return "Elegir una fecha de fin es obligatorio"
                    if (new Date(v).getTime() <= new Date(firstDate).getTime()) return "La fecha de fin debe ser posterior a la de inicio"
                }
            },
        },
        start_hour: {
            condition: recurrent,
            disabled: false,
            type: "time",
            label: "Hora de inicio *",
            name: "start_hour",
            validators: {
                required: "Elegir una hora de inicio es obligatorio",
                validate: (v) => (recurrent || isDefined(v)) || "Elegir una hora de inicio es obligatorio",
            },
        },
        finish_hour: {
            condition: recurrent,
            disabled: true,
            type: "time",
            label: "Hora de fin *",
            name: "finish_hour",
            validators: {
                required: "Elegir una hora de fin es obligatorio",
                validate: (v) => {
                    if (!recurrent) return true
                    if (!isDefined(v)) return "Elegir una hora de fin es obligatorio"
                    if (v <= startHour) return "La hora de fin debe ser posterior a la de inicio"
                }
            },
        },
        comments: {
            condition: true,
            disabled: false,
            type: "textarea",
            label: `Comentario de la sesión (${comment?.length || 0}/10000)`,
            info: "Comentario que solo será visible dentro de la propia sesión",
            placeholder: "...",
            name: "comments",
            validators: {
                maxLength: {
                    value: 10000,
                    message: "El comentario es demasiado largo",
                },
            },
        },
        recurrent: {
            condition: true,
            disabled: false,
            label: "Recurrente",
            type: "checkbox",
            name: "recurrent",
        },
        repeat_monday: {
            condition: recurrent,
            disabled: false,
            info: "Día(s) en el que se repetirá la sesión",
            type: "checkbox",
            label: "Lunes",
            name: "repeat_monday",
            validators: {
                validate: () => !recurrent || repeatSome || "Elegir al menos un día de la semana es obligatorio"
            }
        },
        repeat_tuesday: {
            condition: recurrent,
            disabled: false,
            info: "Día(s) en el que se repetirá la sesión",
            type: "checkbox",
            label: "Martes",
            name: "repeat_tuesday",
            validators: {
                validate: () => !recurrent || repeatSome || "Elegir al menos un día de la semana es obligatorio"
            }
        },
        repeat_wednesday: {
            condition: recurrent,
            disabled: false,
            info: "Día(s) en el que se repetirá la sesión",
            type: "checkbox",
            label: "Miércoles",
            name: "repeat_wednesday",
            validators: {
                validate: () => !recurrent || repeatSome || "Elegir al menos un día de la semana es obligatorio"
            }
        },
        repeat_thursday: {
            condition: recurrent,
            disabled: false,
            info: "Día(s) en el que se repetirá la sesión",
            type: "checkbox",
            label: "Jueves",
            name: "repeat_thursday",
            validators: {
                validate: () => !recurrent || repeatSome || "Elegir al menos un día de la semana es obligatorio"
            }
        },
        repeat_friday: {
            condition: recurrent,
            disabled: false,
            info: "Día(s) en el que se repetirá la sesión",
            type: "checkbox",
            label: "Viernes",
            name: "repeat_friday",
            validators: {
                validate: () => !recurrent || repeatSome || "Elegir al menos un día de la semana es obligatorio"
            }
        },
        repeat_saturday: {
            condition: recurrent,
            disabled: false,
            info: "Día(s) en el que se repetirá la sesión",
            type: "checkbox",
            label: "Sábado",
            name: "repeat_saturday",
            validators: {
                validate: () => !recurrent || repeatSome || "Elegir al menos un día de la semana es obligatorio"
            }
        },
        repeat_sunday: {
            condition: recurrent,
            disabled: false,
            info: "Día(s) en el que se repetirá la sesión",
            type: "checkbox",
            label: "Domingo",
            name: "repeat_sunday",
            validators: {
                validate: () => !recurrent || repeatSome || "Elegir al menos un día de la semana es obligatorio"
            }
        }
    }

    return (
        <>
            <form className={"grid grid-cols-1 md:grid-cols-2 gap-2"}>
                <CustomForm
                    formConfig={formConfig}
                    errors={errors}
                    register={register}
                />
            </form>
            <div className="mt-5 flex space-x-2 sm:space-x-4">
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit(postClass, closeTooltip)}
                    disabled={isSubmitting}
                >
                    Confirmar
                </button>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={closeTooltip}
                    disabled={isSubmitting}
                >
                    Cancelar
                </button>
            </div>
        </>
    );
}

export default RegisterClass;