import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const FormFileInput = ({ customKey, register, config, errors }) => {
	return (
		<div key={customKey} className="form-control">
			{config.label && (
				<label htmlFor={config.name} className="label-text">
					{config.label}{" "}
					{config.info && (
						<div className={`tooltip ${config.tooltipLocation ? config.tooltipLocation : ""}`} data-tip={config.info}>
							<FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
						</div>
					)}
				</label>
			)}

			<div className={`${errors && "tooltip"}`} data-tip={errors?.message}>
				<input
					className={`file-input file-input-bordered file-input-primary text-left w-full ${errors ? "input-error" : "input-primary"
						} disabled:bg-disabled-bg disabled:text-disabled-text placeholder-disabled-text`}
					type={config.type}
					id={config.name}
					disabled={config.disabled}
					{...register(config.name, config.validators ? config.validators : {
						required: false,
						validate: false,
					})}
				/>
			</div>
		</div>
	);
};

export default FormFileInput;
