import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../general/form/CustomForm";
import ModalConfirm from "../general/auxiliar/modal/ModalConfirm";
import { deleteClinicPassword, putClinicPassword } from "../../actions/clinics";

const UpdateClinicPassword = ({ existingProfile = false, clinicId, close }) => {
	const modalId = "delete-clinic-profile-modal";
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		openModal,
		watch,
		errors,
		isSubmitting,
	} = useFormWithDisable(modalId);

	const newPassword = watch("new_info_password");

	const formConfig = {
		new_info_password: {
			condition: true,
			disabled: false,
			type: "password",
			label: "Nueva contraseña *",
			placeholder: "********",
			name: "new_info_password",
			info: "La contraseña debe tener al menos 8 caracteres",
			validators: {
				required: "La nueva contraseña es obligatoria",
				minLength: {
					value: 8,
					message: "La nueva contraseña debe tener al menos 8 caracteres",
				},
			},
		},
		new_info_password_rep: {
			condition: true,
			disabled: false,
			type: "password",
			label: "Repita la nueva contraseña *",
			placeholder: "********",
			name: "new_info_password_rep",
			validators: {
				required: "La confirmación de la nueva contraseña es obligatoria",
				minLength: {
					value: 8,
					message: "La nueva contraseña debe tener al menos 8 caracteres",
				},
				validate: (value) =>
					value === newPassword || "Las contraseñas no son iguales",
			},
		},
		password: {
			condition: existingProfile,
			disabled: false,
			type: "password",
			label: "Contraseña actual *",
			placeholder: "********",
			name: "password",
			validators: {
				required: "La contraseña actual es obligatoria",
			},
		},
	};

	return (
		<div className="mt-5 mb-12">
			<h3 className="text-primary text-xl">Perfil de clínica</h3>

			<p className="lg:text-lg my-3">
				Puedes crear un perfil para la clínica con permisos restringidos. Desde
				este perfil no se puede acceder a la información general del mánager ni
				a la información de otras clínicas. Tampoco se puede exportar
				información ni acceder a la facturación completa.
			</p>

			{existingProfile ? (
				<p className="lg:text-lg my-3">
					Esta clínica ya tiene un perfil registrado. Puedes cambiar la
					contraseña o eliminarlo. Eliminar este perfil no afectará a los datos,
					pero no se podrá volver iniciar sesión con este perfil.
				</p>
			) : (
				<p className="lg:text-lg my-3">
					Esta clínica no tiene un perfil registrado. Para crearlo puedes
					guardar una contraseña, que se asociará con el correo electronico de
					la clínica. Puede iniciar sesión en la misma página de siempre.
				</p>
			)}

			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(putClinicPassword, clinicId, close)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={close}
						disabled={isSubmitting}
					>
						Cancelar
					</button>
					{existingProfile && (
						<button
							className="btn btn-error btn-sm"
							onClick={openModal}
							disabled={isSubmitting}
						>
							Eliminar
						</button>
					)}
				</div>
			</form>

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar la cuenta de la clínica?"
				text="No será posible iniciar sesión como clínica hasta que se cree una nueva contraseña."
				onConfirm={handleSubmitWithoutValidation(
					deleteClinicPassword,
					clinicId,
					close
				)}
			/>
		</div>
	);
};

export default UpdateClinicPassword;
