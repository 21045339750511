import axios from "axios";
import { pick } from "lodash";

export const postPatientAttendsClass = (data, update) => {
	return async () => {
		return axios
			.post(`/api/managers/patient_attends_group_sessions/`, data)
			.then(() => {
				update()
			}).catch(() => { })
	}
}

export const deletePatientFromClass = (classId, patientId, update, recSessions = false) => {
	return async () => {
		return axios.delete(`/api/managers/patient_attends_group_sessions/group_session/${classId}/patient/${patientId}?deleteRecurrent=${recSessions}`)
			.then(() => {
				update()
			}).catch(() => { })
	}
}


export const putPatientAttendsClass = (data, paymentType, update) => {
	let paymentData = {}
	if (paymentType === "acquired_voucher") {
		paymentData = pick(data, ["acquired_voucher_id"]);
	} else if (["cash_register", "cash_register_return"].includes(paymentType)) {
		if (paymentType === "cash_register" && parseFloat(data.paid) > 0) {
			paymentData = pick(data, ["initial_price", "discount", "paid", "payment_method"]);
		} else if (paymentType === "cash_register_return" && Number(data.returned) > 0) {
			paymentData = pick(data, ["initial_price", "discount", "payment_method"]);
			paymentData.paid = -Number(data.returned);
		} else {
			paymentData = pick(data, ["initial_price", "discount"]);
		}
	}

	return async () => {
		return axios.put(
			`/api/managers/patient_attends_group_sessions/group_session/${data.group_session_id}/patient/${data.patient_id}`,
			{ new_info: paymentData }
		).then(() => {
			update()
		}).catch(() => { })
	}
}