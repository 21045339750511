import FormInput from "./FormInput";
import FormSelector from "./FormSelector";
import FormFileInput from "./FormFileInput";
import FormCheckbox from "./FormCheckbox";
import FormTextarea from "./FormTextarea";
import CustomColorSelector from "./CustomColorSelector";
import CustomSessionTypeSelector from "./CustomSessionTypeSelector";
import CustomClassTypeSelector from "./CustomClassTypeSelector";
import CustomPhysioSelector from "./CustomPhysioSelector";
import CustomVoucherSelector from "./CustomVoucherSelector";
import CustomPaymentMethodSelector from "./CustomPaymentMethodSelector";
import CustomPatientSelector from "./CustomPatientSelector";
import CustomPatientsHandler from "./CustomPatientsHandler";
import CustomAcquiredVoucherSelector from "./CustomAcquiredVoucherSelector";
import CustomWeeklyScheduleSelector from "./CustomWeeklyScheduleSelector";

const CustomForm = ({ formConfig, errors, register }) => {
	return (
		<>
			{Object.keys(formConfig).map((key) => {
				if (formConfig[key].condition) {
					const type = formConfig[key].type;
					if (["text", "number", "password", "email", "date", "datetime-local", "time"].includes(type)) {
						return (
							<FormInput
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "selector") {
						return (
							<FormSelector
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "file") {
						return (
							<FormFileInput
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "checkbox") {
						return (
							<FormCheckbox
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "textarea") {
						return (
							<FormTextarea
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "color_selector") {
						return (
							<CustomColorSelector
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (
						["session_type_selector", "session_type_selector_with_metadata"].includes(
							type
						)) {
						return (
							<CustomSessionTypeSelector
								metadata={type === "session_type_selector_with_metadata"}
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (
						["group_session_type_selector", "group_session_type_selector_with_metadata"].includes(
							type
						)) {
						return (
							<CustomClassTypeSelector
								metadata={type === "group_session_type_selector_with_metadata"}
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "physio_selector") {
						return (
							<CustomPhysioSelector
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (
						["voucher_selector", "voucher_selector_with_metadata"].includes(
							type
						)
					) {
						return (
							<CustomVoucherSelector
								metadata={type === "voucher_selector_with_metadata"}
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "acquired_voucher_selector") {
						return (
							<CustomAcquiredVoucherSelector
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "payment_method_selector") {
						return (
							<CustomPaymentMethodSelector
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors[key]}
							/>
						);
					} else if (type === "patient_selector") {
						return (
							<CustomPatientSelector
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors}
							/>
						);
					} else if (type === "patient_handler") {
						return (
							<CustomPatientsHandler
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors}
							/>
						);
					} else if (type === "schedule") {
						return (
							<CustomWeeklyScheduleSelector
								key={key}
								customKey={key}
								register={register}
								config={formConfig[key]}
								errors={errors}
							/>
						)
					} else if (type === "title") {
						return (
							<h3 key={key} className={formConfig[key].classname}>
								{formConfig[key].text}
							</h3>
						);
					}

					return <div key={key}></div>;
				}
			})}
		</>
	);
};

export default CustomForm;
