import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import NavbarLoggedOut from "./NavbarLoggedOut";
import NavbarLoggedIn from "./NavbarLoggedIn";
import NavbarReservation from "./NavbarReservation";
import useScreenSize from "../../../auxiliar/customHooks/useScreenSize";

const Navbar = () => {
	const auth = useSelector((state) => state.auth);
	const screenSize = useScreenSize();
	const fullScreen = useMatch("/full-screen-agenda");
	const landingPage = useMatch("/landing-page");
	const reservation = useMatch("/reservation/*");

	if ((fullScreen && screenSize.width > 400) || landingPage) {
		return null;
	} else if (reservation) {
		return <NavbarReservation />;
	} else if (["manager", "clinic"].includes(auth.user_type)) {
		return <NavbarLoggedIn />;
	} else {
		return <NavbarLoggedOut />;
	}
};

export default Navbar;
