import { useDispatch } from "react-redux";

import { dispatchMessageSave } from "../../store/reducers/messageReducer";

const ShowManagerReferral = ({ code }) => {
	const path = `${process.env.REACT_APP_APP_URL}/landing-page?referral_code=${code}`

	const dispatch = useDispatch();

	const copyToClipboard = (e) => {
		e.preventDefault();

		navigator.clipboard
			.writeText(path)
			.then(() => {
				dispatchMessageSave(dispatch, {
					msg: "¡Código copiado al portapapeles! Ya puedes enviárselo a tus amigos",
					type: "info",
				});
			})
			.catch(() => {
				dispatchMessageSave(dispatch, {
					msg: "Inténtalo de nuevo, ha habido un error al copiar el código",
					type: "error",
				});
			});
	};

	return (
		<div className="my-5">
			<h3 className="text-primary text-2xl">Referidos</h3>

			<p className="lg:text-lg my-3">
				Como nos preocupamos por los fisioterapeutas y queremos que todo el
				mundo pueda disfrutar de MiFisio Agenda hemos creado un sistema de
				referidos. Si invitas a un amigo y este se registra desde tu enlace,
				ambos recibiréis un mes gratis de MiFisio Agenda. Y además... ¡No hay
				límite de referidos!
			</p>

			<p className="lg:text-lg my-3">
				No te olvides de enviar el enlace a tus referidos para que sepamos
				que vienen de tu parte. Si haces click en el botón de abajo se copiará
				automáticamente al portapapeles. ¿A qué esperas para empezar a ahorrar?
			</p>

			<div className="flex flex-col items-center">
				<button className={"btn btn-secondary"} onClick={copyToClipboard}>
					Copiar
				</button>
			</div>
		</div>
	);
};

export default ShowManagerReferral;
