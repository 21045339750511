import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../auxiliar/customHooks/usePaginationFilterEffects";
import { handleSubmit } from "../../auxiliar/auxFunctions";
import { getAllClasses } from "../../actions/class";
import { isDefined } from "../../auxiliar/formatValidators";

const OverviewClassesInPatient = ({ refreshBool, openTooltipEdit }) => {
	const { patient_id: patientId } = useParams();
	const { id: activeClinicId } = useSelector((state) => state.activeClinic);
	const [classes, setClasses] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		per_page: 5,
		page: 1,
		clinic_id: activeClinicId,
		patient_id: patientId,
		order: "date",
	});

	const disabled = usePaginationFilterEffects(
		filter,
		undefined,
		getAllClasses,
		setClasses,
		setFilter,
		refreshBool
	);

	const onEditClassClick = (c) => {
		openTooltipEdit.current({
			...c,
			id: c.group_session_id,
			event_type: "group",
			startAt: c.start_datetime,
			endAt: c.finish_datetime,
		});
	};

	return (
		<>
			{classes.waiting ? (
				<div className="flex items-center justify-center">
					<span className="loading loading-dots loading-lg" />
				</div>
			) : !isDefined(classes.order) ||
				!isDefined(classes.content) ||
				classes.order.length <= 0 ? (
				<p className="lg:text-lg my-3">
					No hay clases registradas, añade el paciente a las clases desde la
					agenda.
				</p>
			) : (
				<div className="overflow-x-auto overflow-y-hidden">
					<table className="table">
						<thead>
							<tr>
								<th>Fecha</th>
								<th className="hidden xs:table-cell">Horario</th>
								<th className="hidden md:table-cell">Fisioterapeuta</th>
								<th className="hidden sm:table-cell">Tipo</th>
								<th />
							</tr>
						</thead>

						<tbody>
							{classes.order.map((classId) => {
								const c = classes.content && classes.content[classId];

								return (
									<tr key={classId}>
										<td>{c.start_datetime.substring(0, 10)}</td>
										<td className="hidden sm:table-cell">
											{c.start_datetime.substring(11, 16)}
											{"-"}
											{c.finish_datetime.substring(11, 16)}
										</td>
										<td className="hidden xs:table-cell sm:hidden">
											{c.start_datetime.substring(11, 16)}
										</td>
										<td className="hidden md:table-cell">
											{c.employee_name}
										</td>
										<td className="hidden sm:table-cell">{c.type}</td>
										<td className="text-center">
											<button
												type="button"
												className="btn btn-primary btn-sm"
												onClick={handleSubmit(onEditClassClick, c)}
											>
												Detalles
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}

			<div className="mt-5 flex justify-end">
				<PaginatorComponent
					pagination={classes.pagination}
					setFilter={setFilter}
					filter={filter}
					disabled={disabled}
				/>
			</div>
		</>
	);
};

export default OverviewClassesInPatient;
