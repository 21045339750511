import { handleSubmit } from "../../../auxiliar/auxFunctions";

const PaginatorComponent = ({ pagination, filter, setFilter, disabled }) => {
	if (pagination && (pagination.has_next || pagination.has_prev)) {
		return (
			<div className="join float-right">
				<button
					className="join-item btn btn-primary btn-sm"
					onClick={handleSubmit(setFilter, {
						...filter,
						page: pagination.curr_page - 1,
					})}
					disabled={!pagination.has_prev || disabled}
				>
					«
				</button>
				<div className="cursor-auto hidden sm:join-item sm:btn sm:btn-primary sm:btn-sm">
					{pagination.curr_page}/{pagination.max_pages}
				</div>
				<button
					className="join-item btn btn-primary btn-sm"
					onClick={handleSubmit(setFilter, {
						...filter,
						page: pagination.curr_page + 1,
					})}
					disabled={!pagination.has_next || disabled}
				>
					»
				</button>
			</div>
		);
	} else return null;
};

export default PaginatorComponent;
