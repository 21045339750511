import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PayWithCashRegister from "../../../components/payment/PayWithCashRegister";
import CustomForm from "../../../components/general/form/CustomForm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { postAcquiredVoucher } from "../../../actions/acquiredVoucher";
import { getPatientDiscount } from "../../../actions/patient";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { sessionsNumberValidators } from "../../../auxiliar/useFormValidators";


const RegisterAcquiredVoucher = () => {
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		watch,
		setValue,
		errors,
		isSubmitting,
	} = useFormWithDisable();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const queryParams = useQueryParams();
	const activeClinicId = useSelector((state) => state.activeClinic.id);

	const voucherData = watch("voucher_data")
	const voucherType = watch("voucher_type_id")
	const defaultRemainingSessions = voucherData?.split("-")?.[1]

	const formConfig = {
		voucher_data: {
			condition: true,
			disabled: false,
			type: "voucher_selector_with_metadata",
			label: "Tipo de bono *",
			name: "voucher_data",
		},
		employee_id: {
			condition: true,
			disabled: false,
			type: "physio_selector",
			label: "Fisioterapeuta *",
			name: "employee_id",
		},
		remaining_sessions: {
			condition: true,
			disabled: false,
			type: "number",
			label: `Sesiones restantes${isDefined(defaultRemainingSessions) ? " *" : ""}`,
			placeholder: "Ejemplo: 10",
			name: "remaining_sessions",
			validators: isDefined(defaultRemainingSessions) ? sessionsNumberValidators : undefined,
		},
		due_date: {
			condition: true,
			disabled: false,
			type: "date",
			label: "Fecha de caducidad *",
			name: "due_date",
			validate: (v) =>
				new Date(v) > new Date() ||
				"La fecha de caducidad debe ser posterior a la fecha actual",
		},
	};

	// sets the default discount for the user
	useEffect(() => {
		fetchData(
			dispatch,
			getPatientDiscount,
			queryParams.get("patient"),
			(r) => setValue("discount_percentage", r?.discount_percentage || 0)
		)
	}, []);

	useEffect(() => {
		if (isDefined(voucherData) && voucherData?.split("-").length === 5) {
			// payment data is handled in the payment component
			const [voucherId, remainingSessions, periodType, periods, price] = voucherData?.split("-");
			const dueDate = new Date();
			if (periodType === "m") dueDate.setMonth(dueDate.getMonth() + parseInt(periods));
			else if (periodType === "w") dueDate.setDate(dueDate.getDate() + parseInt(periods) * 7);

			const finalPrice = Number(price).toFixed(2);

			setValue("voucher_type_id", voucherId);

			// the form needs to load before setting the values
			setTimeout(() => {
				setValue("remaining_sessions", isDefined(remainingSessions) ? remainingSessions : undefined);
				setValue("due_date", dueDate.toISOString().slice(0, 10));
				setValue("initial_price", finalPrice);
				setValue("final_price", finalPrice);
				setValue("total_paid", "0");
				setValue("debt", finalPrice);
				setValue("paid", "0");
				setValue("discount_percentage", "0");
				setValue("discount", "0");
			}, 10);
		} else {
			setValue("voucher_type_id", undefined);
			setValue("remaining_sessions", 0);
			setValue("due_date", undefined);
		}
	}, [voucherData]);

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Adquirir bono</h2>
			<div className="mt-5 mb-12">
				<form className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</form>

				{isDefined(voucherType) && (
					<div className="mt-5">
						<h4 className="text-primary text-lg">Datos de Pago</h4>

						<PayWithCashRegister
							register={register}
							errors={errors}
							setValue={setValue}
							watch={watch}
						/>
					</div>
				)}

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(
							postAcquiredVoucher,
							activeClinicId,
							queryParams.get("patient")
						)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(
							navigate,
							`/patient/${queryParams.get("patient")}`
						)}
						disabled={isSubmitting}
					>
						Cancelar
					</button>
				</div>
			</div>
		</div>
	);
};

export default RegisterAcquiredVoucher;
