import axios from "axios";
import { pick } from "lodash";

export const getAllVouchers = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/voucher_types/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getVoucher = (voucherId, deleted = true) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(voucherId)) {
			navigate(`/unknown?entity=voucher&id=${voucherId}`);
			return;
		}

		return axios
			.get(`/api/managers/voucher_types/${voucherId}?exclude_deleted=${deleted}`)
			.then((response) => {
				return {
					...response.data.content,
					price: Number(response.data.content.price).toFixed(2),
					group_indiv: response.data.content.group_session_type_id
						? "group"
						: "individual",
				};
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=voucher&id=${voucherId}`);
			});
	};
};

export const postVoucher = (data, clinicId) => {
	const voucherData = {
		clinic_id: clinicId,
		...pick(data, ["restricted_by", "name", "price", "validity_period_unit", "validity_periods"]),
		...data.group_indiv === "individual" ? {
			session_type_id: data.session_type_id
		} : {
			group_session_type_id: data.group_session_type_id
		},
		...data.total_sessions !== "" && { total_sessions: data.total_sessions }
	}

	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.post("/api/managers/voucher_types/", voucherData)
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};

export const putVoucher = (data, voucherId, disableEdit) => {
	const voucherData = {
		...pick(data, ["name", "validity_period_unit", "validity_periods"]),
		...data.total_sessions !== "" ? { total_sessions: data.total_sessions } : { total_sessions: null }
	}
	return async () => {
		return axios
			.put(`/api/managers/voucher_types/${voucherId}`, { new_info: voucherData })
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deleteVoucher = (voucherId) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/voucher_types/${voucherId}`)
			.then(() => {
				navigate("/services");
			})
			.catch(() => { });
	};
};

export const getAllSessionsInVoucher = (params) => {
	const { acquiredVoucherId, rest } = params;
	return async () => {
		return axios
			.get(`/api/managers/acquired_vouchers/${acquiredVoucherId}/session-list`, { params: rest })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};
