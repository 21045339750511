import { useEffect, useState, useMemo } from "react";

import CustomForm from '../general/form/CustomForm'
import { debounce } from "../../auxiliar/auxFunctions";
import { isDefined } from '../../auxiliar/formatValidators'
import {
    discountValidators,
    paymentValidators,
    priceValidators
} from "../../auxiliar/useFormValidators";


const PayWithCashRegister = ({
    version = "pay",
    register,
    errors,
    originalValues,
    setValue,
    watch,
    refreshBool
}) => {
    const sessionTypeData = watch("session_type_data"); // Only applies to session
    const voucherData = watch("voucher_data"); // Only applies to acquire voucher
    const initialPrice = watch("initial_price");
    const discountValue = watch("discount");
    const discountPercentage = watch("discount_percentage");
    const totalPaid = watch("total_paid");
    const debt = watch("debt");

    const [debouncedDiscount, setDebouncedDiscount] = useState({
        initialPrice: initialPrice,
        discountPercentage: discountPercentage,
        discountValue: discountValue,
        wait: true
    });

    const formConfig = {
        // cash register payment
        initial_price: {
            condition: true,
            disabled: isDefined(originalValues?.invoice_id),
            type: "number",
            label: "Precio inicial",
            name: "initial_price",
            info: "Precio antes del descuento",
            validators: { validate: (v) => { return isDefined(v) || "El precio incial es obligatorio" } },
            minValue: 0,
        },
        final_price: {
            condition: true,
            disabled: true,
            type: "number",
            label: "Precio final",
            name: "final_price",
            info: "Precio después del descuento",
            placeholder: "0",
            validators: priceValidators,
        },
        total_paid: {
            condition: true,
            disabled: true,
            type: "number",
            label: "Total pagado",
            name: "total_paid",
            info: "Cantidad abonada hasta el momento",
        },
        debt: {
            condition: true,
            disabled: true,
            type: "number",
            label: "Deuda",
            name: "debt",
        },
        discount_percentage: {
            condition: true,
            disabled: isDefined(originalValues?.invoice_id),
            type: "number",
            label: "Porcentaje de descuento",
            placeholder: "Ejemplo: 10",
            name: "discount_percentage",
            validators: discountValidators,
            minValue: 0,
            maxValue: ((Number(initialPrice) - Number(totalPaid)) / Number(initialPrice) * 100).toFixed(2)
        },
        discount: {
            condition: true,
            disabled: isDefined(originalValues?.invoice_id),
            type: "number",
            label: "Descuento en euros",
            placeholder: "Ejemplo: 10",
            name: "discount",
            validators: {
                validate: (v) => {
                    return Number(v) <= (Number(initialPrice) - Number(watch("total_paid")) + (Number(watch("returned") || 0))) ||
                        "El descuento no puede ser mayor que la cantidad que falta por pagar"
                }
            },
            minValue: 0,
            maxValue: (Number(initialPrice) - Number(watch("total_paid"))).toFixed(2),
        },
        paid: {
            condition: version === "pay" && Number(debt) > 0,
            disabled: false,
            type: "number",
            label: "Nuevo pago",
            name: "paid",
            placeholder: "Ejemplo: 0",
            minValue: 0,
            maxValue: (Number(debt) || 0).toFixed(2),
            validators: {
                ...paymentValidators,
                validate: (v) => {
                    const match = paymentValidators.validate(v);
                    if (typeof match === "string") return match;
                    else if (!isDefined(v) || Number(v) === 0) return true;
                    else if (Number(v) > Number(watch("debt"))) return "El pago no puede ser mayor que la deuda";
                },
            },
        },
        returned: {
            condition: version === "return" && Number(watch("total_paid")) > 0,
            disabled: false,
            type: "number",
            label: "Nueva devolución",
            name: "returned",
            placeholder: "Ejemplo: 0",
            minValue: 0,
            maxValue: Number(watch("total_paid")).toFixed(2),
            validators: {
                ...paymentValidators,
                validate: (v) => {
                    const match = paymentValidators.validate(v);
                    if (typeof match === "string") return match;
                    else if (!isDefined(v) || Number(v) === 0) return true
                    else if (Number(v) > Number(watch("total_paid"))) return "La devolución no puede ser mayor que el total pagado";
                },
            },
        },
        payment_method: {
            condition: (version === "pay" && Number(debt) > 0) || (version === "return" && Number(watch("total_paid")) > 0),
            disabled: false,
            type: "payment_method_selector",
            label: `Método de ${version === "return" ? "devolución" : "pago"}`,
            name: "payment_method",
            validators: {
                validate: (v) => {
                    if (
                        (version === "pay" && (!isDefined(watch("paid")) || Number(watch("paid")) === 0)) ||
                        (version === "return" && (!isDefined(watch("returned")) || Number(watch("returned")) === 0))
                    ) return true
                    else if (v !== "select") return true
                    return `Elegir un método de pago es obligatorio si se ha realizado un ${version === "pay" ? "pago" : "reembolso"}`
                }
            },
        },
    }

    const processChange = useMemo(() => debounce((initialPrice, discountPercentage, discountValue, debouncedDiscount) => {
        if (
            debouncedDiscount.initialPrice === Number(initialPrice).toFixed(2) &&
            Math.abs(debouncedDiscount.discountPercentage - discountPercentage) < 1 &&
            Math.abs(debouncedDiscount.discountValue - discountValue) < 0.1
        ) {
            return;
        }

        const price = Number(initialPrice).toFixed(2);
        let finalDiscountPercentage = Number(discountPercentage || 0).toFixed(0);
        let finalDiscountValue = Number(discountValue || 0).toFixed(2);

        if (Number(debouncedDiscount.discountValue).toFixed(2) !== finalDiscountValue) {
            if (Number(finalDiscountValue) > (Number(price) - Number(totalPaid))) {
                finalDiscountValue = (Number(price) - Number(totalPaid)).toFixed(2)
                setValue("discount", finalDiscountValue);
            } else if (Number(finalDiscountValue) < 0) {
                finalDiscountValue = "0"
                setValue("discount", finalDiscountValue);
            }

            finalDiscountPercentage = (Number(finalDiscountValue) / price * 100).toFixed(0);
            setValue("discount_percentage", finalDiscountPercentage);
        } else if (parseInt(debouncedDiscount.discountPercentage) !== finalDiscountPercentage) {
            if (Number(finalDiscountPercentage) > 100) {
                finalDiscountPercentage = "100"
                setValue("discount_percentage", finalDiscountPercentage);
            } else if (Number(finalDiscountPercentage) < 0) {
                finalDiscountPercentage = "0"
                setValue("discount_percentage", finalDiscountPercentage);
            }

            finalDiscountValue = (Number(price) * Number(finalDiscountPercentage) / 100).toFixed(2);
            setValue("discount", finalDiscountValue);
        }

        setDebouncedDiscount({
            initialPrice: price,
            discountPercentage: finalDiscountPercentage,
            discountValue: finalDiscountValue,
            wait: false
        })
    }, 500), []);

    // Only applies to session
    useEffect(() => {
        if (debouncedDiscount.wait || !isDefined(sessionTypeData)) return
        setValue("initial_price", sessionTypeData?.split("-")[2])
    }, [sessionTypeData])

    // Only applies to acquire voucher
    useEffect(() => {
        setValue("initial_price", voucherData?.split("-")?.[3] || 0)
    }, [voucherData])

    // Calculates the already paid amount on the first render
    useEffect(() => {
        const initialPrice = Number(originalValues?.initial_price || 0).toFixed(2)
        const totalPaid = (Number(originalValues?.final_price || 0) - Number(originalValues?.debt || 0) || 0).toFixed(2)
        const discountPercentage = (Number(discountValue) / initialPrice * 100).toFixed(0);

        setValue("total_paid", totalPaid)
        setValue("discount_percentage", discountPercentage);
        setValue("discount", Number(originalValues?.discount || 0).toFixed(2))
        setValue("initial_price", initialPrice)
        setValue("debt", Number(originalValues?.debt || 0).toFixed(2))
    }, [originalValues]);

    // Resets the debounced discount values when the refreshBool changes
    useEffect(() => {
        setDebouncedDiscount({
            initialPrice: undefined,
            discountPercentage: undefined,
            discountValue: undefined,
            wait: true
        })
    }, [refreshBool])

    // Sets the debounced discount when the user inputs a new discount
    useEffect(() => {
        processChange(initialPrice, discountPercentage, discountValue, debouncedDiscount);
    }, [initialPrice, discountPercentage, discountValue]);

    // Calculates the debt when the debounced discount changes
    useEffect(() => {
        if (!isDefined(debouncedDiscount.initialPrice) || debouncedDiscount.wait) return

        const totalPaid = (Number(originalValues?.final_price || 0) - Number(originalValues?.debt || 0) || 0).toFixed(2)
        const discountedValue = Number(debouncedDiscount.discountValue) || 0;
        const price = Number(debouncedDiscount.initialPrice).toFixed(2);
        const debt = (Number(price) - Number(totalPaid) - Number(discountedValue)).toFixed(2);
        const finalPrice = (Number(price) - Number(discountedValue)).toFixed(2);

        setValue("final_price", finalPrice)
        setValue("debt", debt)
    }, [debouncedDiscount]);

    return (
        <form className={"grid grid-cols-1 md:grid-cols-2 gap-3 mt-3 mb-5"}>
            <CustomForm
                formConfig={formConfig}
                errors={errors}
                register={register}
            />
        </form >
    )
}

export default PayWithCashRegister;
