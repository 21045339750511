import { useRef, useState } from "react";
import { faPerson, faPeopleGroup, faTicket } from "@fortawesome/free-solid-svg-icons";

import EditPatient from "../../../components/patient/EditPatient";
import OverviewSessionsInPatient from "../../../components/patient/OverviewSessionsInPatient";
import OverviewClassesInPatient from "../../../components/patient/OverviewClassesInPatient";
import OverviewAcquiredVoucherInPatient from "../../../components/patient/OverviewAcquiredVoucherInPatient";
import OverviewCashRegistersInPatient from "../../../components/patient/OverviewCashRegistersInPatient";
import RegisterSessionPopup from "../../../components/services/session/popupRegisterSession/RegisterSessionPopup";
import EditSessionPopup from "../../../components/services/session/popupEditSession/EditSessionPopup";
import OptionSelector from "../../../components/general/auxiliar/OptionSelector";
import { handleSubmit } from "../../../auxiliar/auxFunctions";

const PatientCard = () => {
	const openTooltipRegister = useRef();
	const openTooltipEdit = useRef();
	const [refreshBool, setRefreshBool] = useState(false);
	const [selected, setSelected] = useState("sessions");
	const refresh = () => setRefreshBool(!refreshBool);

	const options = {
		sessions: {
			condition: true,
			title: "Sesiones",
			icon: faPerson,
			selected: selected === "sessions",
			onClick: handleSubmit(setSelected, "sessions"),
		},
		classes: {
			condition: true,
			title: "Clases",
			icon: faPeopleGroup,
			selected: selected === "classes",
			onClick: handleSubmit(setSelected, "classes"),
		},
		vouchers: {
			condition: true,
			title: "Bonos",
			icon: faTicket,
			selected: selected === "vouchers",
			onClick: handleSubmit(setSelected, "vouchers"),
		},
	};

	return (
		<div  className="sm:container p-3 mx-auto my-5">
			<EditPatient />

			<div className="my-8">

				<div className="flex items-center justify-between">
					<h2 className="text-primary text-2xl">{options[selected]?.title}</h2>

					{/* Displayed service selector */}
					<OptionSelector options={options} />
				</div>

				{selected === "sessions" && <OverviewSessionsInPatient
					refreshBool={refreshBool}
					openTooltipRegister={openTooltipRegister}
					openTooltipEdit={openTooltipEdit}
				/>}

				{selected === "classes" && <OverviewClassesInPatient
					refreshBool={refreshBool}
					openTooltipEdit={openTooltipEdit}
				/>}

				{selected === "vouchers" && <OverviewAcquiredVoucherInPatient
					refreshBool={refreshBool}
				/>}
			</div>

			<OverviewCashRegistersInPatient
				refresh={refresh}
				refreshBool={refreshBool}
			/>

			<RegisterSessionPopup childFunc={openTooltipRegister} refresh={refresh} />

			<EditSessionPopup childFunc={openTooltipEdit} refresh={refresh} />
		</div>
	);
};

export default PatientCard;
