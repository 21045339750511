import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../../../components/general/form/CustomForm";
import { postPhysiotherapist } from "../../../actions/physiotherapist";
import {
	isDefined,
	checkMailFormat,
	checkPhoneNumberFormat,
} from "../../../auxiliar/formatValidators";

const RegisterPhysiotherapist = () => {
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		errors,
		isSubmitting,
		watch,
		setValue
	} = useFormWithDisable();

	const navigate = useNavigate();

	const activeClinicId = useSelector((state) => state.activeClinic.id);
	const addSchedule = watch("add_schedule");

	const formConfig = {
		name: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "name",
			validators: { required: "El nombre es obligatorio" },
		},
		surnames: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Apellidos *",
			placeholder: "Apellidos",
			name: "surnames",
			validators: { required: "Los apellidos son obligatorios" },
		},
		email: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Correo Electrónico",
			placeholder: "fisio@email.com",
			name: "email",
			validators: {
				validate: (v) => !isDefined(v) || checkMailFormat(v),
			},
		},
		phone_number: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Número de teléfono",
			placeholder: "+34 XXX XXX XXX",
			name: "phone_number",
			validators: {
				validate: (v) => !isDefined(v) || checkPhoneNumberFormat(v),
			},
		},
		color: {
			condition: true,
			disabled: false,
			type: "color_selector",
			label: "Color *",
			info: "El color que selecciones será el que aparezca en el calendario con las citas asociadas a este fisioterapeuta.",
			name: "color",
		},
		dummy: {
			condition: true,
			type: "dummy",
		},
		reminders: {
			condition: true,
			disabled: false,
			info: "Si activas los recordatorios podrás recibir mensajes con información de las citas.",
			label: "Activar recordatorios",
			type: "checkbox",
			name: "reminders",
		},
		online_booking: {
			condition: true,
			disabled: false,
			info: "Si lo activas, este fisioterapeuta aparecerá como una opcion al reservar citas online.",
			label: "Citas online",
			type: "checkbox",
			name: "online_booking",
		},
		intern: {
			condition: true,
			disabled: false,
			label: "Estudiante en prácticas",
			type: "checkbox",
			name: "intern",
		},
		add_schedule: {
			condition: true,
			disabled: false,
			label: "Añadir horario de trabajo",
			info: "El horario aparecerá en la agenda automáticamente",
			type: "checkbox",
			name: "add_schedule",
		},
		schedule: {
			condition: addSchedule,
			disabled: false,
			type: "schedule",
			className: "mt-5",
			watch: watch,
			setValue: setValue,
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Registro de Fisioterapeuta</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(postPhysiotherapist, activeClinicId)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(
							navigate,
							"/physiotherapist"
						)}
						disabled={isSubmitting}
					>
						Cancelar
					</button>
				</div>
			</form>
		</div>
	);
};

export default RegisterPhysiotherapist;
