import { Link } from "react-router-dom";

const CookiesPolicy = () => {
	return (
		<main className="max-w-3xl my-5 md:mx-auto">
			<div className="p-5">
				<h1 className="text-3xl pb-6 font-bold">
					Política de Cookies de MiFisio Agenda
				</h1>
				<p className="whitespace-pre-wrap pb-4">
					En este documento se incluye la política de cookies. También le
					recomendamos leer la{" "}
					<Link to="/privacy-policy" className="link">
						política de privacidad
					</Link>{" "}
					y el{" "}
					<Link to="/legal" className="link">
						aviso legal
					</Link>
					.
				</p>

				<h2 className="text-xl pb-4 font-bold">¿Qué son las Cookies?</h2>
				<p className="whitespace-pre-wrap pb-6">
					Una cookie es un fragmento de información que se descarga en el equipo
					terminal de un usuario con la finalidad de almacenar datos que podrán
					ser actualizados y recuperados por la entidad responsable. Las cookies
					permiten a una página web, entre otras cosas, almacenar y recuperar
					información sobre la sesión de navegación de un usuario, sus hábitos
					de navegación de un usuario o de su equipo y, dependiendo de la
					información que contengan y de la forma en que utilice su equipo,
					pueden utilizarse para reconocer al usuario.
				</p>

				<h2 className="text-xl pb-4 font-bold">¿Qué son los scripts?</h2>
				<p className="whitespace-pre-wrap pb-6">
					Un script es un fragmento de código de un programa que se utiliza para
					hacer que nuestra web funcione correctamente y de forma interactiva.
					Este código se ejecuta en nuestro servidor o en tu dispositivo.
				</p>

				<h2 className="text-xl pb-4 font-bold">¿Qué es una baliza web?</h2>
				<p className="whitespace-pre-wrap pb-6">
					Una baliza web (o una etiqueta de píxel) es una pequeña e invisible
					pieza de texto o imagen en una web que se utiliza para monitorear el
					tráfico en una web. Para ello, se almacenan varios datos sobre usted
					mediante estas balizas web.
				</p>

				<h2 className="text-xl pb-4 font-bold">Tipología de Cookies</h2>
				<p className="whitespace-pre-wrap pb-2">
					Una baliza web (o una etiqueta de píxel) es una pequeña e invisible
					pieza de texto o imagen en una web que se utiliza para monitorear el
					tráfico en una web. Para ello, se almacenan varios datos sobre usted
					mediante estas balizas web.
				</p>
				<ul className="list-disc pb-6 mx-8">
					<li>
						Según el plazo de tiempo:
						<ul className="list-disc mx-8">
							<li>
								Cookies de sesión: son aquellas diseñadas para recabar y
								almacenar datos mientras el usuario accede a una página web. Se
								suelen emplear para almacenar información que solo interesa
								conservar para la prestación del servicio solicitado por el
								usuario en una sola ocasión (por ejemplo, una lista de productos
								adquiridos) y desaparecen al terminar la sesión.
							</li>
							<li>
								Cookies persistentes: son aquellas en las que los datos siguen
								almacenados en el terminal y pueden ser accedidos y tratados
								durante un periodo definido por el responsable de la cookie, y
								que puede ir de unos minutos a varios años.
							</li>
						</ul>
					</li>
					<li>
						Según su finalidad:
						<ul className="list-disc mx-8">
							<li>
								Cookies técnicas: son aquellas que permiten al usuario la
								navegación a través de una página web, plataforma o aplicación y
								la utilización de las diferentes opciones o servicios que en
								ella existan, incluyendo aquellas que el editor utiliza para
								permitir la gestión y operativa de la página web y habilitar sus
								funciones y servicios (como por ejemplo, controlar el tráfico y
								la comunicación de datos, identificar la sesión, acceder a
								partes de acceso restringido y finalidades similares de
								naturaleza técnica).
							</li>
							<li>
								Cookies de personalización: son aquellas que permiten recordar
								información para que el usuario acceda al servicio con
								determinadas características que pueden diferenciar su
								experiencia de la de otros usuarios, como, por ejemplo, el
								idioma, el número de resultados a mostrar cuando el usuario
								realiza una búsqueda, el aspecto o contenido del servicio en
								función del tipo de navegador a través del cual el usuario
								accede al servicio o de la región desde la que accede al
								servicio, etc.
							</li>
							<li>
								Cookies de análisis: son aquellas que permiten al responsable de
								las mismas el seguimiento y análisis del comportamiento de los
								usuarios de los sitios web a los que están vinculadas, incluida
								la cuantificación de los impactos de los anuncios. La
								información recogida mediante este tipo de cookies se utiliza en
								la medición de la actividad de los sitios web, aplicación o
								plataforma, con el fin de introducir mejoras en función del
								análisis de los datos de uso que hacen los usuarios del
								servicio.
							</li>
							<li>
								Cookies de publicidad comportamental: son aquellas que almacenan
								información del comportamiento de los usuarios obtenida a través
								de la observación continuada de sus hábitos de navegación, lo
								que permite desarrollar un perfil específico para mostrar
								publicidad en función del mismo.
							</li>
						</ul>
					</li>
					<li>
						Según la entidad que las gestiona:
						<ul className="list-disc mx-8">
							<li>
								Cookies propias: son aquellas que se envían al equipo terminal
								del usuario desde un equipo o dominio gestionado por el propio
								editor y desde el que se presta el servicio solicitado por el
								usuario.
							</li>
							<li>
								Cookies de tercero: son aquellas que se envían al equipo
								terminal del usuario desde un equipo o dominio que no es
								gestionado por el editor, sino que por otra entidad que trata
								los datos obtenidos a través de las cookies.
							</li>
						</ul>
					</li>
				</ul>

				<h2 className="text-xl pb-4 font-bold">
					¿Qué tipo de cookies utiliza esta página web?
				</h2>
				<p className="whitespace-pre-wrap pb-4">
					La presente página web está utilizando cookies que permiten facilitar
					al usuario el uso y la navegación a través de la misma, garantizar el
					acceso a determinados servicios, así como mejorar la configuración
					funcional de la web.
				</p>
				<p className="whitespace-pre-wrap">
					En concreto, esta página web utiliza las siguientes cookies:
				</p>
				<div className="overflow-x-auto pb-6">
					<table className="table-auto w-full">
						<thead>
							<tr>
								<th className="px-4 py-2">Tipo</th>
								<th className="px-4 py-2">Nombre</th>
								<th className="px-4 py-2">De sesión / Persistentes</th>
								<th className="px-4 py-2">Propias / De terceros</th>
								<th className="px-4 py-2">Expiración</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="border px-4 py-2">Técnica</td>
								<td className="border px-4 py-2">Tokens de acceso</td>
								<td className="border px-4 py-2">De sesión</td>
								<td className="border px-4 py-2">Propia</td>
								<td className="border px-4 py-2">Al finalizar la sesión</td>
							</tr>
							<tr>
								<td className="border px-4 py-2">Personalizacón</td>
								<td className="border px-4 py-2">Redux</td>
								<td className="border px-4 py-2">Persistentes</td>
								<td className="border px-4 py-2">Propia</td>
								<td className="border px-4 py-2">-</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h2 className="text-xl pb-4 font-bold">
					¿Cómo gestionar la configuración de las cookies?
				</h2>
				<p className="whitespace-pre-wrap pb-4">
					En el presente apartado se lleva a cabo una breve exposición de cómo
					consultar y llevar a cabo la configuración del sistema de cookies en
					relación a los navegadores más comunes o más utilizados por los
					usuarios
				</p>
				<p className="whitespace-pre-wrap pb-4">
					En este sentido, prácticamente todos los navegadores permiten al
					usuario obtener información general sobre las cookies instaladas en
					una página web, concretamente verificar la existencia de las mismas,
					su duración o el sistema de eliminación.
				</p>
				<p className="whitespace-pre-wrap pb-4">
					Por otra parte, el usuario podrá permitir, bloquear o eliminar las
					cookies instaladas en su equipo modificando la configuración de su
					navegador conforme a las instrucciones indicadas en el mismo. A título
					informativo se indica que habitualmente la configuración de las
					cookies se suele llevar a cabo en el menú “Preferencias” o
					“Herramientas” de cada navegador, en cualquier caso, siempre podrá
					acudir a la ayuda de su navegador para solucionar o solventar las
					dudas que se le generen al respecto.
				</p>
				<p className="whitespace-pre-wrap pb-6">
					Conviene tener en cuenta que, si se impide la instalación de todas las
					cookies, el contenido de la web así como determinadas funcionalidades
					y servicios facilitados por la misma pueden verse afectados.
				</p>

				<h2 className="text-xl pb-4 font-bold">Consentimiento</h2>
				<p className="whitespace-pre-wrap pb-4">
					La legitimación para el tratamiento de sus datos personales viene dada
					por su consentimiento, otorgado en el momento de ponerse en contacto
					con nosotros, para la utilización de cookies analíticas,
					comportamentales y publicitarias; para las cookies de sesión o
					personalización, la legitimación para el tratamiento viene dada por el
					interés legítimo del prestador (responsable del tratamiento).
				</p>
				<p className="whitespace-pre-wrap pb-6">
					Cuando visite nuestra web por primera vez, te mostraremos una ventana
					emergente (“banner”) con una explicación sobre las cookies. Dispone de
					tres opciones: Aceptar, rechazar o configurar. Mediante la opción de
					aceptar autoriza al uso de cookies. Al rechazarlas, la página web no
					recopilará ningún dado mientras se esté navegando en ella. Con la
					opción de configurar podrá seleccionar que cookies autoriza y cuáles
					no.
				</p>

				<h2 className="text-xl pb-4 font-bold">Revocación</h2>
				<p className="whitespace-pre-wrap pb-6">
					En todo momento podrá acceder a la configuración de su navegador
					aceptando o rechazando todas las cookies, o bien seleccionar aquéllas
					cuya instalación admite y cuáles no.
				</p>

				<h2 className="text-xl pb-4 font-bold">Periodo de conservación</h2>
				<p className="whitespace-pre-wrap pb-4">
					La conservación de los datos recogidos a través de las cookies estará
					relacionada en función de la finalidad por la cual se recaba, sin
					perjuicio de lo establecido legalmente.
				</p>

				<h2 className="text-xl pb-4 font-bold">
					Sus derechos con respecto a los datos personales
				</h2>
				<p className="whitespace-pre-wrap pb-2">
					Tiene los siguientes derechos con respecto a tus datos personales:
				</p>
				<ul className="list-disc pb-4 mx-8">
					<li>
						Tiene derecho a saber por qué se necesitan sus datos personales, qué
						sucederá con ellos y durante cuánto tiempo se conservarán.
					</li>
					<li>
						Derecho de acceso: tienes derecho a conocer que datos suyos
						tratamos.
					</li>
					<li>
						Derecho de rectificación: tiene derecho a completar, rectificar,
						borrar o bloquear sus datos personales cuando lo desee.
					</li>
					<li>
						Si nos da su consentimiento para procesar sus datos, tiene derecho a
						revocar dicho consentimiento y a que se elimine sus datos
						personales.
					</li>
					<li>
						Derecho de cesión de tus datos: tiene derecho a solicitar todos sus
						datos personales al responsable del tratamiento y a transferirlos
						íntegramente a otro responsable del tratamiento.
					</li>
					<li>
						Derecho de oposición: puede oponerse al tratamiento de tus datos.
					</li>
				</ul>
				<p className="whitespace-pre-wrap pb-6">
					Para ejercer estos derechos consulte los detalles de contacto en la
					parte inferior de esta política de cookies. Puede enviar una carta
					postal o un correo electrónico indicando en el asunto el derecho a
					ejercitar. Si tiene alguna queja sobre cómo gestionamos tus datos, nos
					gustaría que no la hicieras saber, pero también tiene derecho a enviar
					una queja a la autoridad supervisora (La Agencia Española de
					Protección de Datos).
				</p>

				<h2 className="text-xl pb-4 font-bold">Datos de contacto</h2>
				<p className="whitespace-pre-wrap pb-2">
					Para preguntas y/o comentarios sobre nuestra política de cookies y
					esta declaración, por favor, contacta con nosotros usando los
					siguientes datos de contacto:
				</p>
				<ul className="list-disc pb-4 mx-8">
					<li>Responsable del Tratamiento: Mfisio Schedula S.L.</li>
					<li>
						Dirección postal: C/ Emilio Ferrari, 10. Bajo izq., 28017, Madrid,
						(Madrid).
					</li>
					<li>Dirección electrónica: info@mifisioagenda.com</li>
				</ul>
			</div>
		</main>
	);
};

export default CookiesPolicy;
