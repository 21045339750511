import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faGrip } from "@fortawesome/free-solid-svg-icons";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllVouchers } from "../../../../actions/voucher";
import { handleSubmit } from "../../../../auxiliar/auxFunctions";
import { isDefined } from "../../../../auxiliar/formatValidators";

const OverviewVouchers = () => {
	const navigate = useNavigate();
	const { id: clinicId } = useSelector((state) => state.activeClinic);

	const [vouchers, setVouchers] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		clinic_id: clinicId,
		order: "name",
		per_page: 10,
		page: 1,
	});

	const disabled = usePaginationFilterEffects(
		filter,
		undefined,
		getAllVouchers,
		setVouchers,
		setFilter
	);

	return (
		<div className="sm:container p-3 mx-auto my-2">
			{vouchers.waiting ? (
				<div className="flex items-center justify-center">
					<span className="loading loading-dots loading-lg" />
				</div>
			) : !isDefined(vouchers.order) ||
				!isDefined(vouchers.content) ||
				vouchers.order.length <= 0 ? (
				<p className="lg:text-lg my-3">
					No hay tipos de bono registrados, añade el primero haciendo click en
					el botón de abajo.
				</p>
			) : (
				<div className="overflow-x-auto overflow-y-hidden">
					<table className="table">
						<thead>
							<tr>
								<th />
								<th>Nombre</th>
								<th className="hidden lg:table-cell">Tipo</th>
								<th className="hidden sm:table-cell">Sesiones</th>
								<th className="hidden sm:table-cell">Precio</th>
								<th className="hidden md:table-cell">Validez</th>
								<th />
							</tr>
						</thead>

						<tbody>
							{vouchers.order.map((id) => {
								const voucherType = vouchers.content && vouchers.content[id];

								return (
									<tr key={id}>
										<td className="text-center">
											{voucherType.restricted_by === "sessions" ?
												<FontAwesomeIcon icon={faGrip} alt="Bono de sesiones" /> :
												<FontAwesomeIcon icon={faCalendar} alt="Bono temporal" />
											}
										</td>
										<td className="max-w-xs">{voucherType.name}</td>
										<td className="hidden lg:table-cell max-w-xs">
											{voucherType.type}
										</td>
										<td className="hidden sm:table-cell">
											{voucherType.total_sessions ? voucherType.total_sessions : "Ilimitadas"}
										</td>
										<td className="hidden sm:table-cell">
											{Number(voucherType.price).toFixed(2)}€
										</td>
										<td className="hidden md:table-cell">
											{voucherType.validity_periods} {voucherType.validity_period_unit === "m" ? "meses" : "semanas"}
										</td>
										<td className="text-center">
											<button
												type="button"
												className="btn btn-primary btn-sm"
												onClick={handleSubmit(
													navigate,
													`/services/voucher/${id}`
												)}
											>
												Detalles
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}

			<form className="mt-5 flex justify-between">
				<div className="flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(navigate, "/services/voucher/register")}
					>
						Nuevo tipo de bono
					</button>
				</div>

				<PaginatorComponent
					pagination={vouchers.pagination}
					setFilter={setFilter}
					filter={filter}
					disabled={disabled}
				/>
			</form>
		</div>
	);
};

export default OverviewVouchers;
