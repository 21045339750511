const PaymentPlanDisplay = ({ plan, freeMonths, price }) => {
	const content = {
		baseText: `Disfruta de todos los beneficios de MiFisio Agenda por solo ${price} EUR/mes.`,
		freeMonthsText:
			freeMonths === 0
				? `Recuerda que puedes obtener un mes gratuito por cada persona a la que invites a MiFisio Agenda.`
				: `Además, gracias a tus referidos dispones de ${freeMonths} ${freeMonths === 1 ? "mes gratuito" : "meses gratuitos"
				}.`,
	};

	return (
		<div className="my-5 mb-12">
			<h3 className="text-primary text-2xl">Plan contratado: {plan}</h3>
			<p className="lg:text-lg my-3">
				{content.baseText} {content.freeMonthsText}
			</p>
		</div>
	);
};

export default PaymentPlanDisplay;
