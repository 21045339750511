import FormSelector from "./FormSelector";
import { isDefined } from "../../../auxiliar/formatValidators";

const CustomColorSelector = ({ customKey, register, config, errors }) => {
	const checkColorSelected = (value) => {
		if (!isDefined(value) || value === "select") {
			return "Elegir un color para mostrar los eventos en el calendario es obligatorio";
		}
	};

	const options = [
		{ key: "select", label: "Selecciona un color" },
		{ key: "blue", label: "Azul" },
		{ key: "turquoise", label: "Turquesa" },
		{ key: "green", label: "Verde" },
		{ key: "red", label: "Rojo" },
		{ key: "orange", label: "Naranja" },
		{ key: "yellow", label: "Amarillo" },
		{ key: "violet", label: "Violeta" },
	];

	return (
		<FormSelector
			customKey={customKey}
			register={register}
			config={{
				condition: config.condition,
				disabled: config.disabled,
				type: "selector",
				name: config.name,
				label: config.label,
				info: config.info,
				options: options,
				defaultValue: "select",
				validators: {
					validate: checkColorSelected,
				},
			}}
			errors={errors}
		/>
	);
};

export default CustomColorSelector;
