export const loginTypes = {
	login: '[Auth] login',
	logout: '[Auth] logout'
}

export const redirectTypes = {
	redirect: '[Redirect] redirect',
	clean: '[Redirect] clean'
}

export const messageTypes = {
	add: '[Message] add',
	remove: '[Message] remove',
	clean: '[Message] clean'
}

export const activeClinicTypes = {
	save: '[Active Clinic] save',
	clean: '[Active Clinic] clean'
}

export const themeTypes = {
	save: '[Theme] save',
	clean: '[Theme] clean'
}

export const cookiesTypes = {
	save: '[Cookies] save',
	clean: '[Cookies] clean',
	accept: '[Cookies] accept',
}

export const persistedFilters = {
	save: '[Persisted Filters] save',
	clean: '[Persisted Filters] clean',
	cleanKey: '[Persisted Filters] clean key'
}