import axios from "axios";
import { pick } from "lodash";
import { isDefined } from "../auxiliar/formatValidators";

export const getAllClasses = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/group_sessions/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getClass = (classId) => {
	return async () => {
		return axios
			.get(`/api/managers/group_sessions/${classId}`)
			.then((response) => {
				return {
					...response.data.content,
					date: response.data.content.start_datetime.substring(0, 10),
				};
			})
			.catch(() => { });
	};
};

export const postClass = (
	data,
	closeTooltip,
) => {
	// build the request data
	const session = pick(data, ["clinic_id", "employee_id", "group_session_type_id", "comments", "recurrent"]);

	let schedule = {}
	if (data.recurrent) {
		schedule = pick(data, ["repeat_monday", "repeat_tuesday", "repeat_wednesday", "repeat_thursday", "repeat_friday",
			"repeat_saturday", "repeat_sunday", "start_hour", "finish_hour", "first_date", "last_date"]);
	} else {
		schedule = pick(data, ["start_datetime", "finish_datetime"]);
	}

	const reqData = { ...session, ...schedule };

	// make the request
	return async () => {
		return axios
			.post("/api/managers/group_sessions/", reqData)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const putClass = (
	data,
	classId,
	operation,
	closeTooltip,
) => {
	const classData = pick(data, ["start_datetime", "finish_datetime", "employee_id", "comments"]);
	let newPatient = {}
	let newPayment = {}

	if (
		operation.operation === "addPatient" &&
		data.patient_id === "new" &&
		isDefined(data.patient_name) &&
		isDefined(data.patient_surnames)
	) {
		newPatient = {
			patient_id: "new",
			new_patient_info: {
				name: data.patient_name,
				surnames: data.patient_surnames,
				...(isDefined(data.patient_email) ? { email: data.patient_email } : {}),
				...(isDefined(data.patient_phone_number) ? { phone_number: data.patient_phone_number } : {}),
			}
		}
	}

	if (operation?.metadata?.display === "acquired_voucher") {
		newPayment = pick(data, ["patient_id", "acquired_voucher_id"]);
	} else if (["cash_register", "cash_register_return"].includes(operation?.metadata?.display) && isDefined(operation.metadata?.cash_register_id)) {
		if (Number(data.paid) > 0) {
			newPayment = {
				cash_register_id: operation.metadata?.cash_register_id,
				...pick(data, ["patient_id", "initial_price", "discount", "paid", "payment_method"])
			}
		} else if (Number(data.returned) > 0) {
			newPayment = {
				cash_register_id: operation.metadata?.cash_register_id,
				...pick(data, ["patient_id", "initial_price", "discount", "payment_method"])
			}
			newPayment.paid = -Number(data.returned);
		} else {
			newPayment = pick(data, ["patient_id", "initial_price", "discount"]);
		}
	}

	return async () => {
		return axios
			.put(`/api/managers/group_sessions/${classId}`, {
				new_info: {
					...classData,
					...(Object.keys(newPatient).length > 0 ? { new_patient_attendance: { ...newPatient } } : {}),
					...(Object.keys(newPayment).length > 0 ? { new_payment_info: { ...newPayment } } : {}),
				},
			})
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const deleteClass = (
	classId,
	closeTooltip,
	deleteRecurrent
) => {
	return async () => {
		return axios
			.delete(
				`/api/managers/group_sessions/${classId}?deleteRecurrent=${deleteRecurrent}`
			)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

