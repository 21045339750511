const ModalMultiOption = ({ id, disabled, title, text, options }) => {
	return (
		<dialog id={id} className="modal z-60">
			<div className="modal-box">

				<h3 className="font-bold text-lg text-primary">{title}</h3>
				<p className="py-4">{text}</p>

				<div className="modal-action">
					<form method="dialog" className="mt-2 flex space-x-2 sm:space-x-4">
						{ /* Cancel button present by default */}
						<button
							className="btn btn-primary btn-sm"
							disabled={disabled}
						>
							Cancelar
						</button>

						{ /* Iterate over the options and render them as buttons */}
						{options.map((option, index) => (
							<button
								key={index}
								className="btn btn-primary btn-sm"
								onClick={option.onClick}
								disabled={disabled}
							>
								{option.text}
							</button>
						))}
					</form>
				</div>
			</div>
		</dialog>
	);
};

export default ModalMultiOption;

