import { useEffect } from "react";
import PayWithCashRegister from "./PayWithCashRegister";
import PayWithAcquiredVoucher from "./PayWithAcquiredVoucher";


const PayInClass = ({
    operation,
    register,
    errors,
    setValue,
    watch,
}) => {
    useEffect(() => {
        setValue("group_session_id", operation.metadata?.group_session_id)
        setValue("patient_id", operation.metadata?.patient_id)

        if (operation.metadata?.display === "acquired_voucher") {
            setValue("group_session_type_id", operation.metadata?.group_session_type_id)
        }
    }, [operation, setValue]);

    return (
        <>
            {["cash_register", "cash_register_return"].includes(operation.metadata?.display) &&
                <PayWithCashRegister
                    version={operation.metadata?.display === "cash_register_return" ? "return" : "pay"}
                    register={register}
                    errors={errors}
                    originalValues={operation.metadata}
                    setValue={setValue}
                    watch={watch}
                />
            }

            {operation.metadata?.display === "acquired_voucher" &&
                <PayWithAcquiredVoucher
                    register={register}
                    errors={errors}
                    originalValues={operation.metadata}
                    setValue={setValue}
                    watch={watch}
                />
            }
        </>
    );
}

export default PayInClass;