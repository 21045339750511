import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEuro,
	faPerson,
	faChevronDown,
	faArrowUpRightFromSquare,
	faCircleInfo,
	faEuroSign
} from "@fortawesome/free-solid-svg-icons";

import PayWithCashRegister from "../../../payment/PayWithCashRegister";
import PayWithAcquiredVoucher from "../../../payment/PayWithAcquiredVoucher";
import CustomForm from "../../../general/form/CustomForm";
import ModalMultiOption from "../../../general/auxiliar/modal/ModalMultiOption";
import OptionSelector from "../../../general/auxiliar/OptionSelector";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import { isDefined } from "../../../../auxiliar/formatValidators";
import {
	deleteSession,
	getEditSession,
	putSession
} from "../../../../actions/session";
import { sendInvoiceEmail, postInvoice } from "../../../../actions/invoice";


const EditSession = ({
	closeTooltip,
	orgSessionId,
	openTooltipRegister
}) => {
	const navigate = useNavigate();

	const modalId = "delete-session-modal";
	const {
		register,
		setValue,
		watch,
		resetForm,
		isSubmitting,
		fetchFormValues,
		originalValues,
		errors,
		handleSubmit,
		handleSubmitWithoutValidation,
		openModal
	} = useFormWithDisable(modalId);

	const activeClinic = useSelector((state) => state.activeClinic);

	const sessionTypeData = watch("session_type_data");
	const sessionReason = watch("session_reason");
	const comments = watch("comment");
	const startDatetime = watch("start_datetime");
	const attended = isDefined(watch("attended")) ? watch("attended") : originalValues.attended;

	const [sessionId, setSession] = useState(orgSessionId);
	const [display, setDisplay] = useState("patient");
	const [refreshBool, setRefreshBool] = useState(false);

	// Functions
	const handleClose = () => {
		closeTooltip();
		resetForm();
	}

	const loadNewSession = (id) => {
		resetForm();
		setSession(id);
	};

	const onCopyHandler = (...args) => {
		closeTooltip()
		openTooltipRegister?.current(...args)
	}

	const refresh = () => {
		setRefreshBool(!refreshBool)
	}

	// Selector Configuration
	const selectorOptions = {
		patient: {
			condition: true,
			disabled: false,
			title: "Paciente",
			icon: faPerson,
			selected: display === "patient",
			onClick: () => setDisplay("patient"),
			titleLink: <>
				<FontAwesomeIcon
					icon={faArrowUpRightFromSquare}
					className="text-primary text-sm cursor-pointer"
					onClick={handleSubmitWithoutValidation(navigate, `/patient/${originalValues.patient_id}`)}
				/>
				{isDefined(originalValues?.patient_debt) && (
					<>
						{" "}
						<span
							className="tooltip tooltip-right"
							data-tip={
								Number(originalValues?.patient_debt || 0) > 0 ?
									`La deuda del paciente es de ${Number(originalValues.patient_debt).toFixed(2)}€` :
									"El paciente no tiene deuda"
							}
						>
							<FontAwesomeIcon
								icon={faEuroSign}
								className="text-secondary text-sm"
							/>
						</span>
					</>
				)}
			</>
		},
		payment: {
			condition: true,
			disabled: false,
			title: "Pago",
			icon: faEuro,
			selected: ["cashRegister", "voucher"].includes(display),
			multiOption: {
				cashRegister: {
					condition: originalValues.locked_payment_method !== "acquired_voucher",
					disabled: false,
					title: Number(originalValues.debt) > 0 ? "Pago simple" : "Albarán",
					selected: display === "cashRegister",
					onClick: () => setDisplay("cashRegister"),
					titleLink: <a href={`/cash-register/${originalValues.cash_register_id}`} target="_blank" className="mx-1">
						<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-primary text-sm cursor-pointer" />
					</a>
				},
				payback: {
					condition: originalValues.locked_payment_method === "cash_register" && Number(originalValues?.final_price) - Number(originalValues?.debt) > 0,
					disabled: false,
					title: "Devolución",
					selected: display === "payback",
					onClick: () => setDisplay("payback"),
					titleLink: <a href={`/cash-register/${originalValues.cash_register_id}`} target="_blank" className="mx-1">
						<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-primary text-sm cursor-pointer" />
					</a>
				},
				voucher: {
					condition: originalValues.locked_payment_method !== "cash_register",
					disabled: false,
					title: "Bono adquirido",
					selected: display === "voucher",
					onClick: () => setDisplay("voucher"),
					titleLink: <a href={`/acquired-voucher/${originalValues.acquired_voucher_id}`} target="_blank" className="mx-1">
						<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-primary text-sm cursor-pointer" />
					</a>
				}
			},
		},
	};

	// Form configuration
	const formConfig = {
		// session data
		start_datetime: {
			condition: true,
			disabled: attended || isDefined(originalValues.invoice_id),
			type: "datetime-local",
			label: "Fecha y hora de inicio *",
			name: "start_datetime",
			validators: {
				required: "Elegir una fecha y hora de inicio es obligatorio",
				validate: (v) => isDefined(v) || "Elegir una fecha y hora de inicio es obligatorio",
			},
		},
		finish_datetime: {
			condition: true,
			disabled: true,
			type: "datetime-local",
			label: "Fecha y hora de fin *",
			name: "finish_datetime",
			validators: {
				required: "Elegir una fecha y hora de fin es obligatorio",
				validate: (v) => isDefined(v) || "Elegir una fecha y hora de fin es obligatorio",
			},
		},
		employee_id: {
			condition: true,
			disabled: attended,
			defaultValue: originalValues.employee_id,
			type: "physio_selector",
			label: "Fisioterapeuta",
			name: "employee_id",
		},
		session_type_data: {
			condition: true,
			disabled: attended || isDefined(originalValues.invoice_id),
			defaultValue: originalValues.session_type_id,
			type: "session_type_selector_with_metadata",
			label: "Tipo de sesión",
			name: "session_type_data",
		},
		comment: {
			condition: true,
			disabled: false,
			name: "comment",
			label: `Comentario (${comments?.length || 0}/10000)`,
			type: "textarea",
			validators: {
				maxLength: {
					value: 10000,
					message: "El máximo de caracteres aceptado es 10000",
				},
			},
		},
		session_reason: {
			condition: true,
			disabled: false,
			name: "session_reason",
			label: `Motivo de consulta (${sessionReason?.length || 0}/1000)`,
			type: "textarea",
			validators: {
				maxLength: {
					value: 1000,
					message: "El máximo de caracteres aceptado es 1000",
				},
			},
		},
		attended: {
			condition: true,
			disabled: false,
			type: "checkbox",
			label: "Asistido",
			name: "attended",
		},
	};

	const patientFormConfig = {
		patient_selector: {
			condition: true,
			type: "patient_selector",
			watch: watch,
			filter: {
				clinic_id: activeClinic.id,
				per_page: 5,
				order: "name",
				session_id: orgSessionId,
				patient_id: originalValues?.patient_id,
			},
			setValue: setValue,
			onClientSelect: (data) => {
				setValue("patient_id", data.patient_id);
				setValue("patient_name", data.name);
				setValue("patient_surnames", data.surnames);
				setValue("patient_email", data.email);
				setValue("patient_phone_number", data.phone_number);
			},
		},
	}

	// Multi modal configuration
	const multiModalConfig = {
		recurrent: {
			title: "¿Estás seguro de que quieres eliminar la sesión y las sesiones futuras?",
			text: "No podremos recuperar la información más adelante.",
			options: [
				{
					text: "Esta clase",
					onClick: handleSubmitWithoutValidation(deleteSession, sessionId, handleClose, false)
				},
				{
					text: "Esta y futuras",
					onClick: handleSubmitWithoutValidation(deleteSession, sessionId, handleClose, true)
				},
			]
		},
		nonRecurrent: {
			title: "¿Estás seguro de que quieres eliminar la sesión?",
			text: "No podremos recuperar la información más adelante.",
			options: [
				{
					text: "Confirmar",
					onClick: handleSubmitWithoutValidation(deleteSession, sessionId, handleClose, false)
				},
			]
		}
	};

	// Sets the finish date and hour based on the session type duration
	useEffect(() => {
		if (!isDefined(sessionTypeData)) return;

		const sessionTypeId = sessionTypeData.split("-")[0];
		if (isDefined(sessionTypeId)) {
			setValue("session_type_id", sessionTypeId)
		}

		const sessionDuration = sessionTypeData.split("-")[1] || 60;

		if (isDefined(startDatetime)) {
			let startingTime = new Date(startDatetime).getTime();
			if (isNaN(startingTime)) startingTime = new Date().getTime();

			setValue("finish_datetime", format(
				new Date(startingTime + sessionDuration * 60000),
				"yyyy-MM-dd'T'HH:mm"
			).toString())
		}
	}, [sessionTypeData, startDatetime]);

	useEffect(() => {
		fetchFormValues(getEditSession, sessionId);
	}, [sessionId, refreshBool]);

	if (!isDefined(originalValues) || !isDefined(originalValues.start_datetime)) return null;

	return (
		<div>
			<div className="flex justify-between">
				<h2 className="text-primary text-2xl">
					Editar Sesión
				</h2>
				<div className="flex items-start">
					<div className="dropdown dropdown-end">
						<div
							tabIndex={0}
							role="button"
							className="btn btn-primary btn-sm"
						>
							<span className="hidden lg:inline">Opciones{" "}</span>
							<FontAwesomeIcon
								icon={faChevronDown}
								className="text-base-100"
							/>
						</div>
						<ul
							tabIndex={0}
							className="dropdown-content z-40 menu p-2 shadow bg-base-300 rounded-box w-52"
						>
							{openTooltipRegister && <li
								onClick={handleSubmitWithoutValidation(onCopyHandler, {
									startAt: originalValues.start_datetime,
									endAt: originalValues.finish_datetime,
									employee_id: originalValues.employee_id,
									patient_id: originalValues.patient_id,
									session_type_id: originalValues.session_type_id,
								}, true)}
							>
								<a>Copiar sesión</a>
							</li>}

							<li
								onClick={handleSubmitWithoutValidation(
									navigate,
									`/patient/${originalValues.patient_id}`
								)}
							>
								<a>Detalles del paciente</a>
							</li>

							{originalValues.locked_payment_method === "cash_register" && (
								<>
									<li
										onClick={handleSubmitWithoutValidation(
											navigate,
											`/cash-register/${originalValues.cash_register_id}`
										)}
									>
										<a>Detalles del albarán</a>
									</li>
								</>
							)}

							{originalValues.locked_payment_method === "acquired_voucher" && (
								<>
									<li
										onClick={handleSubmitWithoutValidation(
											navigate,
											`/acquired-voucher/${originalValues.acquired_voucher_id}`
										)}
									>
										<a>Detalles del bono</a>
									</li>
								</>
							)}

							{isDefined(originalValues.invoice_id) ?
								<>
									<li
										onClick={handleSubmitWithoutValidation(
											navigate,
											`/cash-register/${originalValues.cash_register_id}`
										)}
									>
										<a>Detalles de la factura</a>
									</li>
									<li
										onClick={handleSubmitWithoutValidation(
											sendInvoiceEmail,
											originalValues.invoice_id
										)}
									>
										<a>
											Enviar Factura{" "}
											<div
												className="tooltip flex items-center"
												data-tip={
													"La factura se enviará al correo asociado al paciente"
												}
											>
												<FontAwesomeIcon
													icon={faCircleInfo}
													className="text-primary"
												/>
											</div>
										</a>
									</li>
								</> : <>
									<li
										onClick={handleSubmitWithoutValidation(
											postInvoice,
											activeClinic.id,
											originalValues.cash_register_id,
											refresh
										)}
									>
										<a>Crear factura {originalValues.locked_payment_method === "acquired_voucher" ? "de bono" : "de sesión"}</a>
									</li>
								</>
							}
						</ul>
					</div>
					<div className={`join ${(originalValues.previous_session_id || originalValues.next_session_id) ? "mx-2" : "mx-0"}`}>
						{isDefined(originalValues.previous_session_id) && (
							<button
								className={"join-item btn btn-primary btn-sm"}
								onClick={handleSubmitWithoutValidation(loadNewSession, originalValues.previous_session_id)}
							>
								«
							</button>
						)}
						{isDefined(originalValues.next_session_id) && (
							<button
								className={"join-item btn btn-primary btn-sm"}
								onClick={handleSubmitWithoutValidation(loadNewSession, originalValues.next_session_id)}
							>
								»
							</button>
						)}
					</div>
				</div>
			</div>

			<form className={"grid grid-cols-1 md:grid-cols-2 gap-3 mt-3"}>
				<CustomForm
					formConfig={formConfig}
					errors={errors}
					register={register}
				/>
			</form>

			<div className="mt-3">
				<div className="flex items-center justify-between">
					<h2 className="text-primary text-xl">
						{selectorOptions[display]?.title || selectorOptions.payment.multiOption[display]?.title}
						{" "}
						{selectorOptions[display]?.titleLink || selectorOptions.payment.multiOption[display]?.titleLink}
					</h2>
					<OptionSelector options={selectorOptions} />
				</div>

				{display === "patient" && <form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
					<CustomForm
						formConfig={patientFormConfig}
						errors={errors}
						register={register}
					/>
				</form>}

				{["cashRegister", "payback"].includes(display) && <PayWithCashRegister
					version={display === "payback" ? "return" : "pay"}
					register={register}
					errors={errors}
					originalValues={originalValues}
					setValue={setValue}
					watch={watch}
					refreshBool={refreshBool}
				/>}

				{display === "voucher" && <PayWithAcquiredVoucher
					register={register}
					errors={errors}
					originalValues={originalValues}
					setValue={setValue}
					watch={watch}
				/>}
			</div>

			<div className="mt-2 flex space-x-2 sm:space-x-4">
				<button
					className="btn btn-primary btn-sm"
					onClick={handleSubmit(putSession, sessionId, handleClose, display)}
					disabled={isSubmitting}
				>
					Guardar
				</button>
				<button
					className="btn btn-primary btn-sm"
					onClick={handleSubmitWithoutValidation(closeTooltip)}
					disabled={isSubmitting}
				>
					Cancelar
				</button>
				<button
					className="btn btn-error btn-sm"
					onClick={handleSubmitWithoutValidation(openModal)}
					disabled={isSubmitting}
				>
					Eliminar
				</button>
			</div>

			{ /* Modals */}
			<ModalMultiOption
				id={modalId}
				disabled={isSubmitting}
				title={multiModalConfig[originalValues.next_session_id ? "recurrent" : "nonRecurrent"]?.title}
				text={multiModalConfig[originalValues.next_session_id ? "recurrent" : "nonRecurrent"]?.text}
				options={multiModalConfig[originalValues.next_session_id ? "recurrent" : "nonRecurrent"]?.options || []}

			/>
		</div>
	);
};

export default EditSession;
