import axios from "axios";

export const uploadDocument = (data, clinicId, patientId, closeTooltip) => {
	return async () => {
		const formData = new FormData();
		formData.append("clinic_id", clinicId);
		formData.append("patient_id", patientId);
		formData.append("description", "");
		formData.append("file", data.file[0]);

		return axios
			.post("/api/managers/documents/", formData, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const getAllDocuments = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/documents/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const deleteDocument = (id, modalId, refresh) => {
	return async () => {
		return axios
			.delete(`/api/managers/documents/${id}`)
			.then(() => {
				document.getElementById(modalId).close();
				setTimeout(() => refresh(), 200);
			})
			.catch(() => { });
	};
};
