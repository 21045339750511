import CustomForm from '../../components/general/form/CustomForm'

import { isDefined } from '../../auxiliar/formatValidators'


const PayWithAcquiredVoucher = ({
    register,
    errors,
    originalValues,
    setValue,
    watch
}) => {
    const displayAll = watch('display_all') || false;
    const sessionTypeId = watch("session_type_id") || originalValues.session_type_id;
    const classId = watch("group_session_type_id") || originalValues.group_session_type_id;

    const formConfig = {
        // acquired voucher payment
        acquired_voucher_id: {
            condition: true,
            disabled: originalValues.locked_payment_method === "acquired_voucher",
            classname: "col-span-1 md:col-span-2",
            type: "acquired_voucher_selector",
            label: "Bono",
            name: "acquired_voucher_id",
            setValue: setValue,
            patientId: originalValues.patient_id,
            defaultValue: originalValues.acquired_voucher_id,
            displayAll: displayAll,
            sessionTypeId: sessionTypeId,
            groupSessionTypeId: classId,
        },
        display_all: {
            condition: !isDefined(originalValues.locked_payment_method),
            disabled: originalValues.locked_payment_method === "acquired_voucher",
            classname: "col-span-1 md:col-span-2",
            type: "checkbox",
            label: "Mostrar bonos de todos los tipos de sesión",
            name: "display_all",
        },
    }

    return (
        <form className={"grid grid-cols-1 md:grid-cols-2 gap-3 mt-3 mb-5"}>
            <CustomForm
                formConfig={formConfig}
                errors={errors}
                register={register}
            />
        </form >
    )
}

export default PayWithAcquiredVoucher;
