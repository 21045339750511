import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import RegisterChargePopup from "../charge/RegisterChargePopUp";
import CustomForm from "../general/form/CustomForm";
import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import usePaginationFilterEffects from "../../auxiliar/customHooks/usePaginationFilterEffects";
import {
	handleSubmit,
	handleSubmitWithDispatch,
} from "../../auxiliar/auxFunctions";
import { getAllCashRegisters } from "../../actions/cashRegister";
import {
	postInvoice,
	downloadInvoice,
	sendInvoiceEmail,
} from "../../actions/invoice";
import { isDefined } from "../../auxiliar/formatValidators";

const OverviewCashRegistersInPatient = ({ refresh, refreshBool }) => {
	const { register, watch, errors } = useFormWithDisable();
	const writtenFilter = {
		unpaid: watch("unpaid") || false,
		has_invoice: watch("has_invoice") || false,
	};

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { patient_id: patientId } = useParams();
	const { id: activeClinicId } = useSelector((state) => state.activeClinic);
	const [cashRegisters, setCashRegisters] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		per_page: 5,
		page: 1,
		clinic_id: activeClinicId,
		patient_id: patientId,
		order: "issue_date",
	});

	const disabled = usePaginationFilterEffects(filter, writtenFilter, getAllCashRegisters, setCashRegisters, setFilter, refreshBool);

	const formConfig = {
		unpaid: {
			condition: true,
			disabled: false,
			label: "Por pagar",
			info: "Márcalo para mostrar solo los albaranes por pagar",
			type: "checkbox",
			name: "unpaid",
		},
		has_invoice: {
			condition: true,
			disabled: false,
			label: "Factura asociada",
			info: "Márcalo para mostrar solo los albaranes con factura asociada",
			type: "checkbox",
			name: "has_invoice",
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Albaranes</h2>

			<form className="my-5">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>
			</form>

			{cashRegisters.waiting ? (
				<div className="flex items-center justify-center">
					<span className="loading loading-dots loading-lg" />
				</div>
			) : !isDefined(cashRegisters.order) ||
				!isDefined(cashRegisters.content) ||
				cashRegisters.order.length <= 0 ? (
				<p className="lg:text-lg my-3">
					No hay albaranes asocaiado a este paciente.
				</p>
			) : (
				<div className="lg:overflow-x-visible overflow-y-visible">
					<table className="table">
						<thead>
							<tr>
								<th>Tipo</th>
								<th className="hidden sm:table-cell">Emisión</th>
								<th className="hidden sm:table-cell">Precio</th>
								<th>Deuda</th>
								<th className="hidden lg:table-cell">Fisioterapeuta</th>
								<th className="hidden md:table-cell" />
								<th className="hidden md:table-cell" />
								<th />
							</tr>
						</thead>

						<tbody>
							{cashRegisters.order.map((id) => {
								const cashRegister =
									cashRegisters.content && cashRegisters.content[id];

								return (
									<tr key={id}>
										<td className="max-w-xs">{cashRegister.name}</td>
										<td className="hidden sm:table-cell">
											{cashRegister.issue_date}
										</td>
										<td className="hidden sm:table-cell">
											{Number(cashRegister.final_price).toFixed(2)}€
										</td>
										<td>
											{Number(cashRegister.debt) > 0 ?
												(Number(cashRegister.debt).toFixed(2) + "€") :
												"Pagado"
											}
										</td>
										<td className="hidden lg:table-cell">
											{cashRegister.name_employee}
										</td>
										<td className="text-center">
											<div className="dropdown dropdown-end">
												<div
													tabIndex={0}
													role="button"
													className="btn btn-primary btn-sm"
												>
													<span className="hidden xl:inline">Opciones{" "}</span>
													<FontAwesomeIcon
														icon={faChevronDown}
														className="text-base-100"
													/>
												</div>
												<ul
													tabIndex={0}
													className="dropdown-content z-50 menu p-2 shadow bg-base-300 rounded-box w-52"
												>
													<li
														onClick={handleSubmit(
															navigate,
															`/cash-register/${id}`
														)}
													>
														<a>Detalles</a>
													</li>
													{cashRegister.debt > 0 && (
														<li>
															<RegisterChargePopup
																originalValues={cashRegister}
																refresh={refresh}
																trigger={<a>Cobrar</a>}
															/>
														</li>
													)}
													{!isDefined(cashRegister.invoice_id) && (
														<li>
															<button
																type="button"
																className="disabled:cursor-not-allowed disabled:text-disabled-text disabled:bg-disabled-bg"
																disabled={disabled} // TODO: add logic
																onClick={handleSubmitWithDispatch(
																	dispatch,
																	postInvoice,
																	activeClinicId,
																	id,
																	refresh
																)}
															>
																Crear Factura
															</button>
														</li>
													)}
													{isDefined(cashRegister.invoice_id) && (
														<>
															<li
																onClick={handleSubmitWithDispatch(
																	dispatch,
																	downloadInvoice,
																	cashRegister.invoice_id
																)}
															>
																<a>Descargar Factura</a>
															</li>
															<li
																onClick={handleSubmitWithDispatch(
																	dispatch,
																	sendInvoiceEmail,
																	cashRegister.invoice_id
																)}
															>
																<a>
																	Enviar Factura{" "}
																	<div
																		className="tooltip flex items-center"
																		data-tip={
																			"La factura se enviará al correo asociado al paciente"
																		}
																	>
																		<FontAwesomeIcon
																			icon={faCircleInfo}
																			className="text-primary"
																		/>
																	</div>
																</a>
															</li>
														</>
													)}
												</ul>
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}

			<div className="mt-5 flex justify-end">
				<PaginatorComponent
					pagination={cashRegisters.pagination}
					setFilter={setFilter}
					filter={filter}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default OverviewCashRegistersInPatient;
