import { useState, useEffect } from "react";

import CustomForm from "../../../general/form/CustomForm";
import ModalMultiOption from "../../../general/auxiliar/modal/ModalMultiOption";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import { getAbsence, putAbsence, deleteAbsence } from "../../../../actions/absence";
import { isDefined } from "../../../../auxiliar/formatValidators";


const EditAbsence = ({ closeTooltip, orgAbsenceId }) => {
    const modalId = "delete-absence-modal";
    const {
        register,
        watch,
        resetForm,
        isSubmitting,
        fetchFormValues,
        originalValues,
        errors,
        handleSubmit,
        handleSubmitWithoutValidation,
        openModal
    } = useFormWithDisable(modalId);

    const description = watch("description");
    const startDatetime = watch("start_datetime");

    const [absenceId, setAbsenceId] = useState(orgAbsenceId);

    const loadNewAbsence = (id) => {
        resetForm();
        setAbsenceId(id);
    };

    const handleClose = () => {
        closeTooltip();
        resetForm();
    }

    // Form configuration
    const formConfig = {
        employee_id: {
            condition: true,
            disabled: true,
            allowAll: true,
            defaultValue: originalValues.employee_id,
            type: "physio_selector",
            label: "Fisioterapeuta",
            name: "employee_id",

        },
        name: {
            condition: true,
            disabled: false,
            label: "Título *",
            placeholder: "Título de la ausencia",
            name: "name",
            type: "text",
            validators: {
                required: "Escribir un título es obligatorio",
            }
        },
        start_datetime: {
            condition: true,
            disabled: false,
            type: "datetime-local",
            label: "Fecha y hora de inicio *",
            name: "start_datetime",
            validators: {
                required: "Elegir una fecha y hora de inicio es obligatorio",
                validate: (v) => isDefined(v) || "Elegir una fecha y hora de inicio es obligatorio",
            },
        },
        finish_datetime: {
            condition: true,
            disabled: false,
            type: "datetime-local",
            label: "Fecha y hora de fin *",
            name: "finish_datetime",
            validators: {
                required: "Elegir una fecha y hora de fin es obligatorio",
                validate: (v) => {
                    if (!isDefined(v)) return "Elegir una fecha y hora de fin es obligatorio"
                    if (new Date(v).getTime() <= new Date(startDatetime).getTime()) return "La fecha de fin debe ser posterior a la de inicio"
                }
            },
        },
        description: {
            condition: true,
            disabled: false,
            type: "textarea",
            label: `Descripción (${description?.length || 0}/10000)`,
            placeholder: "...",
            name: "description",
            validators: {
                maxLength: {
                    value: 10000,
                    message: "La descripción es demasiado largo",
                },
            },
        },
    }

    // Multi modal configuration
    const multiModalConfig = {
        recurrent: {
            title: "¿Estás seguro de que quieres eliminar la ausencia y las ausencias futuras?",
            text: "No podremos recuperar la información más adelante.",
            options: [
                {
                    text: "Esta ausencia",
                    onClick: handleSubmitWithoutValidation(deleteAbsence, absenceId, handleClose, false)
                },
                {
                    text: "Esta y futuras",
                    onClick: handleSubmitWithoutValidation(deleteAbsence, absenceId, handleClose, true)
                },
            ]
        },
        nonRecurrent: {
            title: "¿Estás seguro de que quieres eliminar la ausencia?",
            text: "No podremos recuperar la información más adelante.",
            options: [
                {
                    text: "Confirmar",
                    onClick: handleSubmitWithoutValidation(deleteAbsence, absenceId, handleClose, false)
                },
            ]
        }
    };

    // use effects
    useEffect(() => {
        fetchFormValues(getAbsence, absenceId);
    }, [absenceId]);

    if (!isDefined(originalValues) || !isDefined(originalValues.calendar_timespan_id)) return null;

    return (
        <div>
            <div className="flex justify-between">
                <h2 className="text-primary text-2xl">
                    Editar Ausencia
                </h2>
                <div>
                    <div className={`join ${(originalValues.previous_calendar_timespan_id || originalValues.next_calendar_timespan_id) ? "mx-2" : "mx-0"}`}>
                        {isDefined(originalValues.previous_calendar_timespan_id) && (
                            <button
                                className={"join-item btn btn-primary btn-sm"}
                                onClick={handleSubmitWithoutValidation(loadNewAbsence, originalValues.previous_calendar_timespan_id)}
                            >
                                «
                            </button>
                        )}
                        {isDefined(originalValues.next_calendar_timespan_id) && (
                            <button
                                className={"join-item btn btn-primary btn-sm"}
                                onClick={handleSubmitWithoutValidation(loadNewAbsence, originalValues.next_calendar_timespan_id)}
                            >
                                »
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <form className={"grid grid-cols-1 md:grid-cols-2 gap-3 mt-3"}>
                <CustomForm
                    formConfig={formConfig}
                    errors={errors}
                    register={register}
                />
            </form>

            <div className="mt-5 flex space-x-2 sm:space-x-4">
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit(putAbsence, absenceId, handleClose)}
                    disabled={isSubmitting}
                >
                    Guardar
                </button>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmitWithoutValidation(handleClose)}
                    disabled={isSubmitting}
                >
                    Cancelar
                </button>
                <button
                    className="btn btn-error btn-sm"
                    onClick={handleSubmitWithoutValidation(openModal)}
                    disabled={isSubmitting}
                >
                    Eliminar
                </button>
            </div>

            { /* Modals */}
            <ModalMultiOption
                id={modalId}
                disabled={isSubmitting}
                title={multiModalConfig[originalValues.next_calendar_timespan_id ? "recurrent" : "nonRecurrent"]?.title}
                text={multiModalConfig[originalValues.next_calendar_timespan_id ? "recurrent" : "nonRecurrent"]?.text}
                options={multiModalConfig[originalValues.next_calendar_timespan_id ? "recurrent" : "nonRecurrent"]?.options || []}

            />
        </div>
    );
}

export default EditAbsence;