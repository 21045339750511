const Pricing = () => {
	const plans = [
		{
			isFeatured: true,
			name: "Suscripción mensual",
			description:
				"Si te registras ahora, te mantenemos el precio durante al menos un año, y ademas, ¡El primer mes es gratis!",
			price: 24,
			priceAnchor: 40,
			features: [
				{ name: "Gestiona fisioterapeutas, pacientes y servicios" },
				{ name: "Crea sesiones, clases y recordatorios" },
				{ name: "Almacena pagos y crea facturas" },
				{ name: "Envía recordatorios por WhatsApp o por correo" },
				{ name: "Servicio de reservas online para tus pacientes" },
				{ name: "Importación de datos desde otros programas de gestión" },
				{ name: "Atención al cliente" },
				{ name: "Descuentos para colegiados y miembros asociaciones" },
			],
		},
	];

	return (
		<section className="bg-base-200 overflow-hidden" id="pricing">
			<div className="py-24 px-8 max-w-5xl mx-auto">
				<div className="flex flex-col text-center w-full mb-20">
					<p className="font-bold text-4xl text-secondary mb-8">Precios</p>
					<h2 className="font-medium text-2xl tracking-tight">
						Informatiza la gestión de tu clínica con MiFisio o lo pagarás con tu
						tiempo
					</h2>
				</div>

				<div className="relative flex justify-center flex-col lg:flex-row items-center lg:items-stretch gap-8">
					{plans.map((plan, index) => (
						<div key={index} className="relative w-full max-w-lg">
							{plan.isFeatured && (
								<div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
									<span
										className={`badge text-xs text-secondary-content font-semibold border-0 bg-secondary`}
									>
										Oferta de Lanzamiento
									</span>
								</div>
							)}

							{plan.isFeatured && (
								<div
									className={`absolute -inset-[1px] rounded-[9px] bg-secondary z-10`}
								></div>
							)}

							<div className="relative flex flex-col h-full gap-5 lg:gap-8 z-10 bg-base-100 p-8 rounded-lg">
								<div className="flex justify-between items-center gap-4">
									<div>
										<p className="text-lg lg:text-xl font-bold">{plan.name}</p>
										{plan.description && (
											<p className="text-base-content/80 mt-2">
												{plan.description}
											</p>
										)}
									</div>
								</div>
								<div className="flex gap-2">
									{plan.priceAnchor && (
										<div className="flex flex-col justify-end mb-[4px] text-lg ">
											<p className="relative">
												<span className="absolute bg-base-content h-[1.5px] inset-x-0 top-[53%]"></span>
												<span className="text-base-content/80">
													{plan.priceAnchor}€
												</span>
											</p>
										</div>
									)}
									<p className={`text-5xl tracking-tight font-extrabold`}>
										{plan.price}€
									</p>
									<div className="flex flex-col justify-end mb-[4px]">
										<p className="text-xs text-base-content/60 uppercase font-semibold">
											EUR / mes
										</p>
									</div>
								</div>
								{plan.features && (
									<ul className="space-y-2.5 leading-relaxed text-base flex-1">
										{plan.features.map((feature, i) => (
											<li key={i} className="flex items-center gap-2">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 20 20"
													fill="currentColor"
													className="w-[18px] h-[18px] opacity-80 shrink-0"
												>
													<path
														fillRule="evenodd"
														d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
														clipRule="evenodd"
													/>
												</svg>

												<span>{feature.name} </span>
											</li>
										))}
									</ul>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Pricing;
