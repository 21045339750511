import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { faMagnifyingGlass, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";

import CustomForm from "./CustomForm";
import OptionSelector from "../auxiliar/OptionSelector";
import WhatsAppReminder from "../auxiliar/WhatsAppReminder";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { getAllPatients } from "../../../actions/patient";
import {
    isDefined,
    checkMailFormat,
    checkPhoneNumberFormat,
} from "../../../auxiliar/formatValidators";
import { handleSubmit, debounce } from "../../../auxiliar/auxFunctions";

const CustomPatientSelector = ({ register, config, errors }) => {
    const dispatch = useDispatch();

    const [whatsAppLink, setWhatsAppLink] = useState();
    const patientComments = config.watch("patient_comments") || "";
    const writtenFilter = {
        name: config.watch("patient_name") || "",
        surnames: config.watch("patient_surnames") || "",
        email: config.watch("patient_email") || "",
        phone_number: config.watch("patient_phone_number") || "",
        patient_id: config.watch("patient_id"), // necessary for internal state
    }

    const [debouncedFilter, setDebouncedFilter] = useState(writtenFilter);
    const [patients, setPatients] = useState({ waiting: true, uniqueLoaded: false });

    const processChange = useMemo(() => debounce((newFilter) => setDebouncedFilter(newFilter), 300), []);

    const setPatientsAux = (data) => {
        if (
            isDefined(config?.filter?.patient_id) &&
            data?.order?.length === 1 &&
            data?.order?.includes(config?.filter?.patient_id?.toString())
        ) {
            const patient = data?.content?.[data?.order?.[0]]
            setWhatsAppLink(patient?.link)
            config.onClientSelect({ ...patient })
        }

        setPatients({ ...data, uniqueLoaded: true })
    };

    // Selector Configuration
    const selectorOptions = {
        existing_patient: {
            title: "Existente",
            condition: !isDefined(config?.filter?.patient_id),
            icon: faMagnifyingGlass,
            selected: debouncedFilter.patient_id !== "new",
            onClick: handleSubmit(config.setValue, "patient_id", null),
            disabled: config.disabled,
        },
        new_patient: {
            title: "Nuevo",
            condition: !isDefined(config?.filter?.patient_id),
            icon: faPlus,
            selected: debouncedFilter.patient_id === "new",
            onClick: handleSubmit(config.setValue, "patient_id", "new"),
            disabled: config.disabled,
        },
        register: {
            title: "Registrar",
            condition: !isDefined(config?.filter?.patient_id) && isDefined(config.onClientRegister),
            icon: faSave,
            selected: undefined,
            onClick: handleSubmit(config.onClientRegister, { patient_id: "new" }),
            disabled: config.disabled || debouncedFilter.patient_id !== "new",
        },
    };

    // Form Configuration
    const formConfig = {
        patient_name: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(config.filter.patient_id),
            type: "text",
            label: `Nombre${debouncedFilter.patient_id === "new" ? " *" : ""}`,
            placeholder: "Nombre",
            name: "patient_name",
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        return isDefined(v) || "El nombre es obligatorio";
                    } else {
                        return isDefined(debouncedFilter.patient_id) || "Seleccionar un paciente es obligatorio"
                    }
                },
            }
        },
        patient_surnames: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(config.filter.patient_id),
            type: "text",
            label: `Apellidos${debouncedFilter.patient_id === "new" ? " *" : ""}`,
            placeholder: "Apellidos",
            name: "patient_surnames",
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        return isDefined(v) || "Los apellidos son obligatorios";
                    } else {
                        return isDefined(debouncedFilter.patient_id) || "Seleccionar un paciente es obligatorio"
                    }
                }
            },
        },
        patient_email: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(config.filter.patient_id),
            type: "text",
            label: "Correo Electrónico",
            placeholder: "paciente@email.com",
            name: "patient_email",
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        return !isDefined(v) || checkMailFormat(v);
                    } else {
                        return true;
                    }
                }
            }
        },
        patient_phone_number: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(config.filter.patient_id),
            type: "text",
            label: "Número de teléfono",
            placeholder: "+34 XXX XXX XXX",
            name: "patient_phone_number",
            whatsAppLink: whatsAppLink,
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        return !isDefined(v) || checkPhoneNumberFormat(v);
                    } else {
                        return true;
                    }
                }
            }
        },
        patient_comments: {
            condition: isDefined(config?.filter?.patient_id),
            disabled: false,
            name: "patient_comments",
            label: `Anamnesis (${patientComments?.length || 0}/10000)`,
            type: "textarea",
            validators: {
                maxLength: {
                    value: 10000,
                    message: "El máximo de caracteres aceptado es 10000",
                },
            },
        },
    };

    useEffect(() => {
        processChange(writtenFilter);
    }, [JSON.stringify(writtenFilter)]);

    useEffect(() => {
        // this if is necessary even if it seems redundant, dont remove it
        if (
            isDefined(config?.filter?.patient_id) &&
            patients?.order?.length === 1 &&
            patients?.order?.includes(config?.filter?.patient_id?.toString())
        ) {
            setPatients({ ...patients, uniqueLoaded: true })
            const patient = patients?.content?.[patients?.order?.[0]]
            setWhatsAppLink(patient?.link)
            config.onClientSelect({ ...patient })
        } else if (
            (!patients.uniqueLoaded && isDefined(config?.filter?.patient_id)) ||
            !isDefined(config?.filter?.patient_id)
        ) {
            if (debouncedFilter.patient_id !== "new") {
                setPatients({ ...patients, uniqueLoaded: true })

                fetchData(
                    dispatch,
                    getAllPatients,
                    {
                        ...config.filter,
                        name: debouncedFilter.name,
                        surnames: debouncedFilter.surnames,
                        email: debouncedFilter.email,
                        phone_number: debouncedFilter.phone_number
                    },
                    setPatientsAux
                );
            }
        }
    }, [debouncedFilter, dispatch, setPatients]);

    return (
        <div className="col-span-1 md:col-span-2">
            <div className="mx-auto my-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    { /* Title and buttons only rendered when there is no patient already assigned in the config */}
                    {!isDefined(config?.filter?.patient_id) && (
                        <>
                            <h2 className="text-primary text-xl">{config.keyword || "Seleccionar"} paciente</h2>

                            <div className="flex gap-3 md:justify-end">
                                <OptionSelector options={selectorOptions} />
                            </div>
                        </>
                    )}

                    { /* Filter for patients or selected patient data */}
                    <CustomForm
                        formConfig={formConfig}
                        errors={errors}
                        register={register}
                    />
                </div>
            </div>

            { /* Patients table when there is no patient currently selected nor in the config */}
            {
                !isDefined(debouncedFilter.patient_id) && !isDefined(config.filter.patient_id) &&
                (patients.waiting ? (
                    <div className="flex items-center justify-center">
                        <span className="loading loading-dots loading-lg" />
                    </div>
                ) : !isDefined(patients.order) ||
                    !isDefined(patients.content) ||
                    patients.order.length <= 0 ? (
                    <p className="lg:text-lg my-3">
                        No hay pacientes registrados con estos datos.
                    </p>
                ) : (
                    <div className="overflow-x-auto overflow-y-hidden">
                        <p className="text-primary my-3">
                            {patients.pagination.total_items} Pacientes encontrados
                        </p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th className="hidden sm:table-cell">Apellidos</th>
                                    <th className="hidden md:table-cell">Correo</th>
                                    <th className="hidden sm:table-cell">Teléfono</th>
                                    <th />
                                </tr>
                            </thead>

                            <tbody>
                                {patients.order.map((patientId) => {
                                    const patient =
                                        patients.content && patients.content[patientId];

                                    return (
                                        <tr key={patientId}>
                                            <td className="sm:hidden">{`${patient.name} ${patient.surnames}`}</td>
                                            <td className="hidden sm:table-cell">{patient.name}</td>
                                            <td className="hidden sm:table-cell">
                                                {patient.surnames}
                                            </td>
                                            <td className="hidden md:table-cell">{patient.email}</td>
                                            <td className="hidden sm:table-cell">
                                                <div className="flex">
                                                    <span>{patient.phone_number}</span>
                                                    <WhatsAppReminder link={patient.link} />
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    onClick={handleSubmit(config.onClientSelect, patient)}
                                                    disabled={config.disabled}
                                                >
                                                    {config.keyword || "Seleccionar"}
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ))
            }
        </div >
    );
};

export default CustomPatientSelector;
