const Hero = ({ variant = "physio" }) => {
	return (
		<section className="max-w-7xl mx-auto bg-base-100 grid grid-cols-1 md:grid-cols-3 items-center justify-center gap-16 lg:gap-20 px-8 py-8 lg:py-10">
			<div className="flex flex-col gap-10 lg:gap-14 text-center items-center">
				<h1 className="font-extrabold text-4xl lg:text-6xl tracking-tight text-primary">
					About Us
				</h1>
				<div>
					<img src={require("../../../media/icon/icon_125.png")} alt={"Logo"} />
				</div>
			</div>
			<div className="md:col-span-2">
				<div className="flex my-5 md:my-20 justify-center">
					<div className="bg-base-200 shadow-xl rounded-lg p-10">
						<p className="text-secondary text-2xl text-center my-5">
							Encantados de conocerte
						</p>

						{
							variant === "physio" && <>
								<p className="text-center text-base lg:text-lg my-3">
									Somos Álvaro, Jose y Diego, los cofundadores de MiFisio Agenda. Si
									has llegado hasta aquí, es porque estás interesado en digitalizar
									tu clínica de fisioterapia.
								</p>

								<p className="text-center text-base lg:text-lg my-3">
									Como ya sabrás, MiFisio Agenda es un software de gestión de
									clínicas con una visión innovadora donde lo mas relevante es
									ayudar a los usuarios. Como fisioterapeutas estábamos cansados de
									utilizar programas antiguos, desfasados y que no se adaptan a
									nuestras necesidades, por lo que decidimos crear nuestro propio
									software y compartirlo con la comunidad.
								</p>

								<h2 className="text-center text-base lg:text-lg my-3">
									Por estos motivos, MiFisio Agenda tiene un enfoque muy claro:
									simplificarte la vida. Queremos que puedas gestionar tu clínica de
									una forma rápida, intuitiva y sin preocupaciones. Con este
									objetivo hemos creado una solución integral para la gestión de
									clínicas de fisioterapia donde no tienes que preocuparte por nada.
								</h2>
							</>
						}

						{
							variant === 'patient' && <>
								<p className="text-center text-base lg:text-lg my-3">
									Somos Álvaro, Jose y Diego, los cofundadores de MiFisio Agenda. Si
									has llegado hasta aquí, es porque tú clínica está utilizando MiFisio
									Agenda (y si no lo hacen todavía, ¿a qué están esperando?).
								</p>

								<p className="text-center text-base lg:text-lg my-3">
									Como ya sabrás, MiFisio Agenda es un software de gestión de
									clínicas de fisioterapia con una visión innovadora donde lo
									más relevante es ayudar a los fisioterapeutas y los pacientes a
									tener la mejor experiencia posible. Para ello, ofrecemos un
									servicio de reservas online con el objetivo de facilitar la
									interacción.
								</p>

								<p className="text-center text-base lg:text-lg my-3">
									Por estos motivos, MiFisio Agenda tiene un enfoque muy claro:
									simplificarte la vida. Queremos que puedas gestionar tus reservas de
									una forma rápida, intuitiva y sin preocupaciones.
								</p>
							</>
						}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
