import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import CustomForm from "../../../components/general/form/CustomForm";
import { postSessionType } from "../../../actions/sessionTypes";
import {
	priceValidators,
	sessionLengthValidators,
} from "../../../auxiliar/useFormValidators";

const RegisterSessionType = () => {
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		errors,
		isSubmitting,
	} = useFormWithDisable();

	const navigate = useNavigate();
	const { id: clinicId } = useSelector((state) => state.activeClinic);

	const formConfig = {
		name: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "name",
			validators: { required: "El nombre es obligatorio" },
		},
		session_duration: {
			condition: true,
			disabled: false,
			type: "number",
			label: "Duración de la sesión *",
			placeholder: "Ejemplo: 60",
			info: "Duración de la sesión en minutos",
			name: "session_duration",
			validators: sessionLengthValidators,
			minValue: 1,
		},
		price: {
			condition: true,
			disabled: false,
			type: "number",
			label: "Precio de la sesión *",
			placeholder: "Ejemplo: 30",
			name: "price",
			info: "Precio de la sesión en euros",
			validators: priceValidators,
			minValue: 0,
		},
		color: {
			condition: true,
			disabled: false,
			type: "color_selector",
			label: "Color *",
			info: "El color que selecciones será el que aparezca en el calendario con las citas asociadas a este tipo de sesión.",
			name: "color",
		},
		online_booking: {
			condition: true,
			disabled: false,
			info: "Si lo activas, este tipo de sesión aparecerá como una opcion al reservar citas online.",
			label: "Citas online",
			type: "checkbox",
			name: "online_booking",
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Registro de Tipo de Sesión</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmit(postSessionType, clinicId)}
						disabled={isSubmitting}
					>
						Confirmar
					</button>
					<button
						className="btn btn-primary btn-sm"
						onClick={handleSubmitWithoutValidation(navigate, "/services")}
						disabled={isSubmitting}
					>
						Cancelar
					</button>
				</div>
			</form>
		</div>
	);
};

export default RegisterSessionType;
