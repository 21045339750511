import { useMatch, useNavigate } from "react-router-dom";

const CTA = () => {
	const landingPage = useMatch("/landing-page")
	const navigate = useNavigate();

	const handleClick = () => {
		if (landingPage) {
			const element = document.getElementById('top');
			element.scrollIntoView({ behavior: 'smooth' });
		}
		else {
			navigate('/register');
		}
	};

	return (
		<section className="relative hero overflow-hidden">
			<div className="hidden lg:flex bg-base-100 h-[660px] w-full">
				<img
					src="https://images.unsplash.com/photo-1522898467493-49726bf28798?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
					alt="Background"
					className="object-cover h-full w-full"
				/>
			</div>

			<div className="relative hero-overlay bg-neutral bg-opacity-70 hidden lg:flex"></div>

			<div className="relative hero-content text-center p-8">
				<div className="flex flex-col items-center max-w-xl p-8 md:p-0 lg:text-neutral-content">
					<h2 className="font-bold text-3xl md:text-5xl tracking-tight mb-8 md:mb-12">
						Ahorra tiempo y dinero
					</h2>
					<p className="text-4xl opacity-80 mb-12 md:mb-16">
						Céntrate en tus pacientes
					</p>

					<button
						className="btn btn-primary"
						onClick={handleClick}
					>
						Registrate
					</button>
				</div>
			</div>
		</section>
	);
};

export default CTA;
