import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import PaginatorComponent from "../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllCharges } from "../../actions/charge";
import { isDefined } from "../../auxiliar/formatValidators";
import { handleSubmitWithDispatch } from "../../auxiliar/auxFunctions";
import { deleteCharge } from "../../actions/charge";

const OverviewCharges = ({
	cashRegisterId,
	boolRefresh,
	refresh
}) => {
	const dispatch = useDispatch();

	const activeClinicId = useSelector((state) => state.activeClinic.id);
	const [charges, setCharges] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		cash_register_id: cashRegisterId,
		clinic_id: activeClinicId,
		order: "charge_date",
		per_page: 5,
		page: 1,
	});

	const disabled = usePaginationFilterEffects(
		filter,
		undefined,
		getAllCharges,
		setCharges,
		setFilter,
		boolRefresh
	);

	return (
		<div className="my-3">
			{charges.waiting ? (
				<div className="flex items-center justify-center">
					<span className="loading loading-dots loading-lg" />
				</div>
			) : !isDefined(charges.order) ||
				!isDefined(charges.content) ||
				charges.order.length <= 0 ? (
				<p className="lg:text-lg my-3">
					El paciente todavía no ha realizado ningún pago.
				</p>
			) : (
				<div className="overflow-x-auto overflow-y-hidden">
					<table className="table">
						<thead>
							<tr>
								<th className="hidden md:table-cell">Tipo</th>
								<th>Fecha</th>
								<th>Cantidad</th>
								<th className="hidden sm:table-cell">Método de pago</th>
										<th />
							</tr>
						</thead>

						<tbody>
							{charges.order.map((id) => {
								const charge = charges.content && charges.content[id];

								return (
									<tr key={id}>
										<td className="hidden md:table-cell">{Number(charge.paid) >= 0 ? "Pago" : "Devolución"}</td>
										<td>{charge.charge_date}</td>
										<td>{Number(charge.paid).toFixed(2)}</td>
										<td className="hidden sm:table-cell">{charge.payment_method}</td>
										<td>
											<button
												type="button"
												className="btn btn-error btn-sm"
												onClick={handleSubmitWithDispatch(dispatch, deleteCharge, id, refresh)}
											>
												<FontAwesomeIcon icon={faTrash} alt="Eliminar"/>
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}

			<div className="mt-5 flex justify-end">
				<PaginatorComponent
					pagination={charges.pagination}
					setFilter={setFilter}
					filter={filter}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default OverviewCharges;
