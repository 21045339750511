import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

function useFormWithDisable(modalId = undefined) {
	const dispatch = useDispatch();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [fetchedValues, setFetchedValues] = useState({});

	const { register, handleSubmit, trigger, watch, setValue, formState, reset } =
		useForm();

	useEffect(() => {
		if (process.env.NODE_ENV !== "production" && Object.keys(formState.errors).length > 0) {
			console.log("The form has the following errors:", formState.errors)
		}
	}, [formState.errors])

	const handleSubmitAux = async (actionCreator, ...args) => {
		setIsSubmitting(true);

		if (modalId) {
			document.getElementById(modalId)?.close();
		}

		try {
			if (
				actionCreator.name.length === 0 ||
				actionCreator.name.substring(0, 5) === "bound" ||
				actionCreator.name.substring(0, 8) === "dispatch" ||
				actionCreator.name.substring(0, 12) === "closeTooltip" ||
				actionCreator.name.substring(0, 11) === "openTooltip" ||
				actionCreator.name === "loadNewSession" ||
				actionCreator.name === "openModal" ||
				actionCreator.name === "openModalAux" ||
				actionCreator.name === "handleClose" ||
				actionCreator.name === "resetFetchedValues" ||
				actionCreator.name === "onCopyHandler" ||
				actionCreator.name === "restartOperation"
			) {
				// Execute directly specific actions
				await actionCreator(...args);
			} else {
				// Dispatch the action if it's a regular one
				await dispatch(actionCreator(...args));
			}
		} catch (error) {
			if (process.env.NODE_ENV !== "production") {
				console.log("action name:", actionCreator.name, "error:", error);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleSubmitWithValidation = (actionCreator, ...args) =>
		handleSubmit((data) => {
			setFetchedValues(data);
			handleSubmitAux(actionCreator, data, ...args)
		});

	const handleSubmitWithoutValidation =
		(actionCreator, ...args) =>
			(e) => {
				e?.preventDefault();
				handleSubmitAux(actionCreator, ...args);
			};

	const openModal = (e) => {
		e?.preventDefault();
		if (modalId) {
			document.getElementById(modalId)?.showModal();
		}
	};

	const fetchValues = async (fetchDefaultValues, ...args) => {
		if (fetchDefaultValues) {
			const defaultValues = await dispatch(fetchDefaultValues(...args));
			setFetchedValues(defaultValues);
			setValuesAux(defaultValues);
		}
	};

	const resetFetchedValues = () => {
		setValuesAux(fetchedValues)
	};

	const setValuesAux = (values, nestedKey) => {
		if (values && Object.keys(values).length > 0) {
			setTimeout(async () => {
				if (!nestedKey) reset();
				for (const [key, value] of Object.entries(values)) {
					const customKey = nestedKey ? `${nestedKey}.${key}` : key;
					if (typeof value === "object") {
						setValuesAux(value, customKey);
					}
					else {
						setValue(customKey, value);
					}
				}
			}, 200);
		}
	}

	return {
		register,
		watch,
		handleSubmit: handleSubmitWithValidation,
		handleSubmitWithoutValidation,
		openModal,
		setValue,
		validate: trigger,
		fetchFormValues: fetchValues,
		resetForm: reset,
		resetFetchedValues: resetFetchedValues,
		originalValues: fetchedValues,
		isSubmitting,
		errors: formState.errors,
	};
}

export default useFormWithDisable;
