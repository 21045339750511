import { useEffect } from "react";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import CustomForm from "../../../components/general/form/CustomForm";
import ModalConfirm from "../../../components/general/auxiliar/modal/ModalConfirm";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import {
	deletePhysiotherapist,
	getPhysiotherapist,
	putPhysiotherapist,
} from "../../../actions/physiotherapist";
import {
	isDefined,
	checkMailFormat,
	checkPhoneNumberFormat,
} from "../../../auxiliar/formatValidators";

const EditPhysiotherapist = () => {
	const modalId = "delete-physio-modal";
	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		fetchFormValues,
		openModal,
		resetFetchedValues,
		setValue,
		watch,
		originalValues,
		errors,
		isSubmitting,
	} = useFormWithDisable(modalId);

	const navigate = useNavigate();
	const [isEditing, setIsEditing] = useState(false);
	const { physio_id: physioId } = useParams();

	const formConfig = {
		name: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "name",
			validators: { required: "El nombre es obligatorio" },
		},
		surnames: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Apellidos *",
			placeholder: "Apellidos",
			name: "surnames",
			validators: { required: "Los apellidos son obligatorios" },
		},
		email: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Correo Electrónico",
			placeholder: "fisio@email.com",
			name: "email",
			validators: {
				validate: (v) => !isDefined(v) || checkMailFormat(v),
			},
		},
		phone_number: {
			condition: true,
			disabled: !isEditing,
			type: "text",
			label: "Número de teléfono",
			placeholder: "+34 XXX XXX XXX",
			name: "phone_number",
			validators: {
				validate: (v) => !isDefined(v) || checkPhoneNumberFormat(v),
			},
		},
		color: {
			condition: true,
			disabled: !isEditing,
			type: "color_selector",
			label: "Color *",
			info: "El color que selecciones será el que aparezca en el calendario con las citas asociadas a este fisioterapeuta.",
			name: "color",
		},
		dummy: {
			condition: true,
			type: "dummy",
		},
		reminders: {
			condition: true,
			disabled: !isEditing,
			info: "Si activas los recordatorios podrás recibir mensajes con información de las citas.",
			label: "Activar recordatorios",
			type: "checkbox",
			name: "reminders",
		},
		online_booking: {
			condition: true,
			disabled: !isEditing,
			info: "Si lo activas, este fisioterapeuta aparecerá como una opcion al reservar citas online.",
			label: "Citas online",
			type: "checkbox",
			name: "online_booking",
		},
		intern: {
			condition: true,
			disabled: !isEditing,
			label: "Estudiante en prácticas",
			type: "checkbox",
			name: "intern",
		},
		active: {
			condition: true,
			disabled: !isEditing,
			info: "Si desactivas un fisioterapeuta no podrás asignarle nuevas sesiones.",
			label: "Activo",
			type: "checkbox",
			name: "active",
		},
		schedule: {
			condition: true,
			disabled: !isEditing,
			type: "schedule",
			watch: watch,
			setValue: setValue,
			originalValues: originalValues?.schedule,
		},
	};

	useEffect(() => {
		if (physioId) fetchFormValues(getPhysiotherapist, physioId);
	}, [physioId]);

	useEffect(() => {
		if (!isEditing && physioId) resetFetchedValues();
	}, [isEditing]);

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Fisioterapeuta</h2>
			<form className="mt-5 mb-12">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					<CustomForm
						formConfig={formConfig}
						errors={errors}
						register={register}
					/>
				</div>

				<div className="mt-5 flex space-x-2 sm:space-x-4">
					{!isEditing ? (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(setIsEditing, true)}
								disabled={isSubmitting}
							>
								Editar
							</button>
							<button
								className="btn btn-error btn-sm"
								onClick={openModal}
								disabled={isSubmitting}
							>
								Eliminar
							</button>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(navigate, -1)}
								disabled={isSubmitting}
							>
								Atrás
							</button>
						</>
					) : (
						<>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmit(
									putPhysiotherapist,
									physioId,
									handleSubmitWithoutValidation(setIsEditing, false)
								)}
								disabled={isSubmitting}
							>
								Guardar
							</button>
							<button
								className="btn btn-primary btn-sm"
								onClick={handleSubmitWithoutValidation(setIsEditing, false)}
								disabled={isSubmitting}
							>
								Cancelar
							</button>
						</>
					)}
				</div>
			</form>

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar todos los datos del fisioterapeuta?"
				text="No podremos recuperar la información más adelante."
				onConfirm={handleSubmitWithoutValidation(
					deletePhysiotherapist,
					physioId
				)}
			/>
		</div>
	);
};

export default EditPhysiotherapist;
