import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PaginatorComponent from "../../../components/general/auxiliar/PaginatorComponent";
import { getAllPhysiotherapists } from "../../../actions/physiotherapist";
import { handleSubmit, fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";

const OverviewPhysiotherapists = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id: clinicId } = useSelector((state) => state.activeClinic);

	const [physios, setPhysios] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		clinic_id: clinicId,
		order: "name",
		per_page: 10,
		page: 1,
	});

	useEffect(() => {
		fetchData(dispatch, getAllPhysiotherapists, filter, setPhysios);
	}, [filter, dispatch, setPhysios]);

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<h2 className="text-primary text-2xl">Fisioterapeutas</h2>

			<div className="sm:container p-3 mx-auto my-2">
				{physios.waiting ? (
					<div className="flex items-center justify-center">
						<span className="loading loading-dots loading-lg" />
					</div>
				) : !isDefined(physios.order) ||
					!isDefined(physios.content) ||
					physios.order.length <= 0 ? (
					<p className="lg:text-lg my-3">
						No hay fisioterapeutas registrados, añade el primero haciendo click en
						el botón de abajo.
					</p>
				) : (
					<div className="overflow-x-auto overflow-y-hidden">
						<table className="table">
							<thead>
								<tr>
									<th>Nombre</th>
									<th className="hidden sm:table-cell">Teléfono</th>
									<th className="hidden xs:table-cell">Activo</th>
									<th />
								</tr>
							</thead>

							<tbody>
								{physios.order.map((physioId) => {
									const physio = physios.content && physios.content[physioId];

									return (
										<tr key={physioId}>
											<td>{`${physio.name} ${physio.surnames}`}</td>
											<td className="hidden sm:table-cell">
												{physio.phone_number}
											</td>
											<td className="hidden xs:table-cell">
												{physio.active ? "Activo" : "Inactivo"}
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={handleSubmit(
														navigate,
														`/physiotherapist/${physioId}`
													)}
												>
													Detalles
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}

				<form className="mt-5 flex justify-between">
					<div className="flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(navigate, "/physiotherapist/register")}
						>
							Nuevo fisioterapeuta
						</button>
					</div>

					<PaginatorComponent
						pagination={physios.pagination}
						setFilter={setFilter}
						filter={filter}
					/>
				</form>
			</div>
		</div>
	);
};

export default OverviewPhysiotherapists;
