import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { faEuro } from "@fortawesome/free-solid-svg-icons";

import CustomForm from "../../../general/form/CustomForm";
import PayInClass from "../../../payment/PayInClass";
import ModalMultiOption from "../../../general/auxiliar/modal/ModalMultiOption";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import {
    getClass,
    putClass,
    deleteClass,
} from "../../../../actions/class";
import {
    postPatientAttendsClass,
    deletePatientFromClass,
    putPatientAttendsClass
} from "../../../../actions/patientAttendsClass";
import { isDefined } from "../../../../auxiliar/formatValidators";
import CustomPatientsHandler from "../../../general/form/CustomPatientsHandler";


const EditClass = ({
    closeTooltip,
    orgClassId,
}) => {
    const dispatch = useDispatch();
    const modalId = "edit-class-dual-modal";

    const {
        register,
        setValue,
        watch,
        resetForm,
        isSubmitting,
        fetchFormValues,
        originalValues,
        errors,
        openModal,
        handleSubmit,
        handleSubmitWithoutValidation,
    } = useFormWithDisable(modalId);

    const activeClinic = useSelector((state) => state.activeClinic);

    const [classId, setClassId] = useState(orgClassId);
    const [classType, setClassType] = useState({
        classTypeId: undefined,
        sessionDuration: 60,
        price: 0,
        maxParticipants: 0,
        reload: false,
    })
    const [operation, setOperation] = useState({
        patient_id: undefined,
        operation: "displayAssisting",
        update: false,
        recurrent: false,
        metadata: undefined
    });

    const comments = watch("comments");
    const startDatetime = watch("start_datetime");
    const classData = watch("group_session_type_data");

    const modalHandler = (operation) => (data) => {
        if (operation === "addPatient") {
            if (originalValues?.next_group_session_id) {
                setOperation({
                    patient_id: data.patient_id,
                    operation,
                    update: operation.update,
                    recurrent: originalValues?.next_group_session_id
                });
                openModal();
            } else {
                dispatchAddPatientToClass(data, false);
            }
        } else if (operation === "deletePatient") {
            setOperation({
                patient_id: data.patient_id,
                operation,
                update: operation.update,
                recurrent: data.recurrent
            });
            openModal();
        } else if (operation === "deleteClass") {
            setOperation({
                patient_id: undefined,
                operation,
                update: operation.update,
                recurrent: originalValues?.next_group_session_id
            });
            openModal();
        }
    }

    const dispatchAddPatientToClass = async (data, recursive) => {
        const patient = data?.patient_id || operation.patient_id
        return dispatch(postPatientAttendsClass(
            {
                clinic_id: activeClinic.id,
                group_session_id: classId,
                patient_id: patient,
                addToRecSessions: recursive,
                new_patient_info: patient === 'new' ? {
                    name: watch("patient_name"),
                    surnames: watch("patient_surnames"),
                    email: watch("patient_email"),
                    phone_number: watch("patient_phone_number"),
                } : undefined
            },
            cleanAfterOperation
        ));
    };

    const cleanAfterOperation = () => {
        setOperation({
            patient_id: undefined,
            operation: "displayAssisting",
            update: !operation.update,
            recurrent: undefined
        });

        setValue("patient_name", "");
        setValue("patient_surnames", "");
        setValue("patient_email", "");
        setValue("patient_phone_number", "");
        setValue("patient_id", null);
        setValue("paid", 0);
        setValue("returned", 0);
        setValue("payment_method", "select");
    };

    const restartOperation = () => {
        setOperation({
            patient_id: undefined,
            operation: "displayAssisting",
            update: operation.update,
            recurrent: undefined
        });
    };

    const handleClose = () => {
        closeTooltip();
        restartOperation();
        resetForm();
    };

    // Sets the classType params and if it is not defined, forces a reload
    useEffect(() => {
        if (!isDefined(classData)) {
            setTimeout(() => setClassType({
                classTypeId: undefined,
                sessionDuration: 60,
                price: 0,
                maxParticipants: 0,
                reload: !classType.reload,
            }), 200);
            return;
        }

        // save metadata
        const classId = classData?.split("-")[0];
        const sessionDuration = parseInt(classData.split("-")[1]);
        const price = parseFloat(classData.split("-")[2]);
        const maxParticipants = parseInt(classData.split("-")[3]);

        setClassType({
            classTypeId: classId,
            sessionDuration,
            price,
            maxParticipants,
            reload: classType.reload,
        });
    }, [classData, classType.reload]);

    useEffect(() => {
        if (isDefined(startDatetime)) {
            let startingTime = new Date(startDatetime).getTime();
            if (isNaN(startingTime)) startingTime = new Date().getTime();

            setValue("finish_datetime", format(
                new Date(startingTime + classType.sessionDuration * 60000),
                "yyyy-MM-dd'T'HH:mm"
            ).toString())
        }
    }, [classType.sessionDuration, startDatetime]);

    useEffect(() => {
        fetchFormValues(getClass, classId);
        restartOperation();
    }, [classId]);

    const loadNewSession = (id) => {
        resetForm();
        setClassId(id);
    };

    // Form configuration
    const sessionFormConfig = {
        employee_id: {
            condition: true,
            disabled: new Date(originalValues?.start_datetime) < new Date(),
            defaultValue: originalValues?.employee_id,
            type: "physio_selector",
            label: "Fisioterapeuta *",
            name: "employee_id",
        },
        group_session_type_data: {
            condition: true,
            disabled: true,
            defaultValue: originalValues?.group_session_type_id,
            type: "group_session_type_selector_with_metadata",
            label: "Tipo de Clase",
            name: "group_session_type_data",
        },
        start_datetime: {
            condition: true,
            disabled: new Date(originalValues?.start_datetime) < new Date(),
            type: "datetime-local",
            label: "Fecha y hora de inicio *",
            name: "start_datetime",
            validators: {
                required: "Elegir una fecha y hora de inicio es obligatorio",
                validate: (v) => isDefined(v) || "Elegir una fecha y hora de inicio es obligatorio",
            },
        },
        finish_datetime: {
            condition: true,
            disabled: true,
            type: "datetime-local",
            label: "Fecha y hora de fin",
            name: "finish_datetime",
            validators: {
                required: "Elegir una fecha y hora de fin es obligatorio",
                validate: (v) => isDefined(v) || "Elegir una fecha y hora de fin es obligatorio",
            },
        },
        comments: {
            condition: true,
            disabled: false,
            name: "comments",
            label: `Comentario (${comments?.length || 0}/10000)`,
            type: "textarea",
            validators: {
                maxLength: {
					value: 10000,
					message: "El máximo de caracteres aceptado es 10000",
				},
            },
        },
    };

    // Multi-modal configuration
    const multiModalConfig = {
        addPatient: {
            recurrent: {
                title: "Añadir paciente",
                text: "¿Desea añadir al paciente a una clase o a todas las clases futuras?",
                options: [
                    {
                        text: "Esta clase",
                        onClick: operation?.metadata?.patient_id === "new" ?
                            handleSubmit(dispatchAddPatientToClass, false) :
                            handleSubmitWithoutValidation(dispatchAddPatientToClass, {}, false)
                    },
                    {
                        text: "Esta y futuras",
                        onClick: operation?.metadata?.patient_id === "new" ?
                            handleSubmit(dispatchAddPatientToClass, true) :
                            handleSubmitWithoutValidation(dispatchAddPatientToClass, {}, true)
                    },]
            }
        },
        deletePatient: {
            recurrent: {
                title: "¿Estás seguro de que quieres eliminar al paciente de la clase?",
                text: "¿Quiere eliminar el paciente de la clase o de todas las clases futuras? No podremos recuperar la información más adelante.",
                options: [
                    {
                        text: "Esta clase",
                        onClick: handleSubmitWithoutValidation(deletePatientFromClass, classId, operation.patient_id, cleanAfterOperation, false)
                    },
                    {
                        text: "Esta y futuras",
                        onClick: handleSubmitWithoutValidation(deletePatientFromClass, classId, operation.patient_id, cleanAfterOperation, true)
                    }
                ]
            },
            nonRecurrent: {
                title: "¿Estás seguro de que quieres eliminar al paciente de la clase?",
                text: "No podremos recuperar la información más adelante.",
                options: [
                    {
                        text: "Confirmar",
                        onClick: handleSubmitWithoutValidation(deletePatientFromClass, classId, operation.patient_id, cleanAfterOperation, false)
                    }
                ]
            }
        },
        deleteClass: {
            recurrent: {
                title: "¿Estás seguro de que quieres eliminar la clase?",
                text: "¿Quieres eliminar la clase o de todas las clases futuras? No podremos recuperar la información de la misma ni de los pacientes que asistían.",
                options: [
                    {
                        text: "Esta clase",
                        onClick: handleSubmitWithoutValidation(deleteClass, classId, handleClose, false)
                    },
                    {
                        text: "Esta y futuras",
                        onClick: handleSubmitWithoutValidation(deleteClass, classId, handleClose, true)
                    }
                ]
            },
            nonRecurrent: {
                title: "¿Estás seguro de que quieres eliminar la clase?",
                text: "No podremos recuperar la información de la misma ni de los pacientes que asistían.",
                options: [
                    {
                        text: "Confirmar",
                        onClick: handleSubmitWithoutValidation(deleteClass, classId, handleClose, false)
                    }
                ]
            }
        }
    }

    // Custom options
    const selectorOptions = {
        pay: {
            condition: true,
            disabled: operation.operation !== "pay",
            title: "Pago",
            icon: faEuro,
            selected: operation.operation === "pay",
            disabledInfo: "Debes seleccionar un paciente en las opciones de abajo. Después podrás seleccionar el método de pago haciendo click aquí.",
            multiOption: {
                cashRegister: {
                    condition: operation?.metadata?.locked_payment_method !== "acquired_voucher",
                    disabled: operation.operation !== "pay",
                    title: Number(operation?.metadata?.debt) ? "Pago simple" : "Albarán",
                    selected: operation.operation === "pay" && operation?.metadata?.display === "cash_register",
                    onClick: handleSubmitWithoutValidation(setOperation, {
                        ...operation,
                        metadata: { ...operation.metadata, display: "cash_register" }
                    })
                },
                cashRegisterReturn: {
                    condition: operation?.metadata?.locked_payment_method === "cash_register" && Number(operation?.metadata?.final_price) - Number(operation?.metadata?.debt) > 0,
                    disabled: operation.operation !== "pay",
                    title: "Devolución",
                    selected: operation.operation === "pay" && operation?.metadata?.display === "cash_register_return",
                    onClick: handleSubmitWithoutValidation(setOperation, {
                        ...operation,
                        metadata: { ...operation.metadata, display: "cash_register_return" }
                    })
                },
                voucher: {
                    condition: operation?.metadata?.locked_payment_method !== "cash_register",
                    disabled: operation.operation !== "pay",
                    title: "Bono adquirido",
                    selected: operation.operation === "pay" && operation?.metadata?.display === "acquired_voucher",
                    onClick: handleSubmitWithoutValidation(setOperation, {
                        ...operation,
                        metadata: { ...operation.metadata, display: "acquired_voucher" }
                    })
                },
                save: {
                    condition: operation?.metadata?.locked_payment_method !== "acquired_voucher",
                    disabled: operation.operation !== "pay",
                    title: "Guardar",
                    selected: false,
                    onClick: handleSubmit(putPatientAttendsClass, operation.metadata?.display, cleanAfterOperation)
                }
            },
        },
    }

    if (!isDefined(originalValues) || !isDefined(originalValues?.start_datetime)) return null;

    return (
        <div>
            { /* Title and buttons */}
            <div className="flex justify-between">
                <h2 className="text-primary text-2xl">
                    Editar Clase
                </h2>
                <div>
                    <div className={`join ${(originalValues?.previous_group_session_id || originalValues?.next_group_session_id) ? "mx-2" : "mx-0"}`}>
                        {isDefined(originalValues?.previous_group_session_id) && (
                            <button
                                className={"join-item btn btn-primary btn-sm"}
                                onClick={handleSubmitWithoutValidation(loadNewSession, originalValues?.previous_group_session_id)}
                            >
                                «
                            </button>
                        )}
                        {isDefined(originalValues?.next_group_session_id) && (
                            <button
                                className={"join-item btn btn-primary btn-sm"}
                                onClick={handleSubmitWithoutValidation(loadNewSession, originalValues?.next_group_session_id)}
                            >
                                »
                            </button>
                        )}
                    </div>
                </div>
            </div>

            { /* Class form */}
            <form className={"grid grid-cols-1 md:grid-cols-2 gap-3 mt-3"}>
                <CustomForm
                    formConfig={sessionFormConfig}
                    errors={errors}
                    register={register}
                />
            </form>

            { /* Already added and new patient form */}
            <CustomPatientsHandler
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                filter={{
                    clinic_id: activeClinic.id,
                    exclude_group_session_id: classId,
                    per_page: 5,
                    order: "name",
                }}
                filterAdded={{
                    clinic_id: activeClinic.id,
                    group_session_id: classId,
                }}
                maxParticipants={classType.maxParticipants}
                disabled={isSubmitting}
                operation={operation}
                onExistingClick={handleSubmitWithoutValidation(restartOperation)}
                onAddNewClick={handleSubmitWithoutValidation(setOperation, {
                    patient_id: undefined,
                    operation: "addPatient",
                    update: operation.update
                })}
                onClientSelect={modalHandler("addPatient")}
                onClientRegister={handleSubmit(modalHandler("addPatient"))}
                onDeletePatient={modalHandler("deletePatient")}
                onPaymentClick={(patient) => handleSubmitWithoutValidation(setOperation, {
                    patient_id: patient.patient_id,
                    operation: "pay",
                    update: operation.update,
                    metadata: {
                        ...patient,
                        group_session_id: classId,
                        group_session_type_id: classType.classTypeId,
                        display: patient.locked_payment_method || "cash_register",
                        // isDefined(patient.locked_payment_method) ?
                        //     patient.locked_payment_method === "acquired_voucher" ?
                        //         "acquired_voucher" :
                        //         patient.debt > 0 ? "cash_register" : "cash_register_return"
                        //     : "cash_register"
                    }
                })}
                otherOptionsInHeader={selectorOptions}
            />

            { /* Payment */}
            {operation.operation === "pay" && <PayInClass
                operation={operation}
                register={register}
                errors={errors}
                setValue={setValue}
                watch={watch}
            />}

            { /* Buttons */}
            <div className="mt-5 flex space-x-2 sm:space-x-4">
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmit(putClass, classId, operation, handleClose)}
                    disabled={isSubmitting}
                >
                    Guardar
                </button>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSubmitWithoutValidation(handleClose)}
                    disabled={isSubmitting}
                >
                    Cancelar
                </button>
                <button
                    className="btn btn-error btn-sm"
                    onClick={handleSubmitWithoutValidation(modalHandler, "deleteClass")}
                    disabled={isSubmitting}
                >
                    Eliminar clase
                </button>
            </div>

            { /* Modals */}
            <ModalMultiOption
                id={modalId}
                disabled={isSubmitting}
                title={multiModalConfig[operation.operation]?.[operation.recurrent ? "recurrent" : "nonRecurrent"]?.title}
                text={multiModalConfig[operation.operation]?.[operation.recurrent ? "recurrent" : "nonRecurrent"]?.text}
                options={multiModalConfig[operation.operation]?.[operation.recurrent ? "recurrent" : "nonRecurrent"]?.options || []}
            />
        </div>
    );
};

export default EditClass;
