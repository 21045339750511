import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { isDefined } from "../../../auxiliar/formatValidators";

const WhatsAppReminder = ({ link }) => {
	if (!isDefined(link)) return null;

	return (
		<a href={link} target="_blank" className="mx-1">
			<FontAwesomeIcon icon={faWhatsapp} className="text-secondary text-lg" />
		</a>
	);
};

export default WhatsAppReminder;
